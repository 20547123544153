/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useRef, useState } from 'react';

import { disableScrolling } from 'bundles/browse/utils';
import SimplifiedExploreButton from 'bundles/megamenu/components/SimplifiedExploreButton';
import SimplifiedMegaMenuContainer from 'bundles/megamenu/components/SimplifiedMegaMenuContainer';

import _t from 'i18n!nls/megamenu';

type Props = {
  shouldShowCourseraPlusButton?: boolean;
};

const styles = {
  simplifiedMegaMenuOverlay: css`
    background-color: inherit;
    position: fixed;
    left: 0;
    z-index: 3000;
    width: 100vw;
  `,
};

/**
 * A wrapper component that manages the simplified megamenu navigation experience.
 *
 * This component handles the display and interaction logic for the simplified megamenu,
 * including opening/closing behaviors, positioning, focus management, and keyboard navigation.
 * It renders the explore button trigger and conditionally displays the megamenu overlay
 * when activated.
 *
 * Key features:
 * - Manages the open/closed state of the megamenu
 * - Provides hover and keyboard-based interaction modes
 * - Ensures proper positioning relative to the triggering button
 * - Handles focus tracking and keyboard accessibility (ESC to close)
 * - Prevents page scrolling when the megamenu is open
 * - Automatically closes when focus moves outside the menu
 * - Prevents accidental opening when search is active
 */
const SimplifiedMegaMenuWrapper = ({ shouldShowCourseraPlusButton }: Props) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement | null>(null);
  const cursorIsOnMenuOrExploreButton = useRef<boolean | undefined>(undefined);
  const exploreButtonRef = useRef<HTMLElement | null>();

  const setButtonRef = (exploreButton: HTMLButtonElement | null) => {
    if (exploreButton) {
      exploreButtonRef.current = exploreButton;
    }
  };

  const getMenuTopPosition = () => {
    // space between explore button and megamenu top position
    const menuTopPositionGap = 13;
    let menuTopPosition = 65;
    if (exploreButtonRef.current) {
      menuTopPosition = exploreButtonRef.current.getBoundingClientRect().bottom + menuTopPositionGap;
    }
    return {
      top: `${menuTopPosition}px`,
      height: `calc(100% - ${menuTopPosition}px)`,
    };
  };

  // close megamenu when component and button lose focus or esc key is pressed
  const handleFocusOut = (event: FocusEvent) => {
    if (divRef.current && event.relatedTarget instanceof Node && !divRef.current.contains(event.relatedTarget)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      closeMenu();
    }
  };
  const handleKeyUp = (event: KeyboardEvent) => {
    if (divRef.current && event.key === 'Escape') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      closeMenu();
    }
  };

  const attachEventListeners = () => {
    document.addEventListener('focusout', handleFocusOut);
    document.addEventListener('keyup', handleKeyUp);
  };

  const detatchEventListeners = () => {
    document.removeEventListener('focusout', handleFocusOut);
    document.removeEventListener('keyup', handleKeyUp);
  };
  // END OF - close megamenu when component and button lose focus or esc key is pressed

  // don't open the megamenu on hover when the search bar is in focus.
  const isFocusOnSearchBar = () => {
    const searchBar = document.getElementById('search-autocomplete-input');
    const isSearchBarActive = document.activeElement === searchBar;
    return isSearchBarActive;
  };

  const setMenuOverlay = () => {
    if (cursorIsOnMenuOrExploreButton.current) {
      attachEventListeners();
      disableScrolling(true);
      setMenuIsOpen(true);
    }
  };

  const openMenu = () => {
    cursorIsOnMenuOrExploreButton.current = true;
    if (!menuIsOpen && !isFocusOnSearchBar()) {
      setTimeout(() => {
        setMenuOverlay();
      }, 100);
    }
  };

  const openMenuUsingKeyboard = () => {
    cursorIsOnMenuOrExploreButton.current = true;
    if (!menuIsOpen) {
      setMenuOverlay();
    }
  };

  const closeMenu = () => {
    cursorIsOnMenuOrExploreButton.current = false;
    setTimeout(() => {
      if (!cursorIsOnMenuOrExploreButton.current) {
        detatchEventListeners();
        disableScrolling(false);
        setMenuIsOpen(false);
      }
    }, 100);
  };

  return (
    <div data-testid="simplified-megaMenu-wrapper" ref={divRef}>
      <SimplifiedExploreButton
        setButtonRef={setButtonRef}
        menuIsOpen={menuIsOpen}
        openMenu={openMenu}
        openMenuUsingKeyboard={openMenuUsingKeyboard}
        closeMenu={closeMenu}
      />
      {menuIsOpen && (
        <div css={styles.simplifiedMegaMenuOverlay} style={getMenuTopPosition()}>
          <nav aria-label={_t('Main Menu')}>
            <SimplifiedMegaMenuContainer
              menuIsOpen={menuIsOpen}
              openMenu={openMenu}
              closeMenu={closeMenu}
              shouldShowCourseraPlusButton={shouldShowCourseraPlusButton}
            />
          </nav>
        </div>
      )}
    </div>
  );
};

export default SimplifiedMegaMenuWrapper;
