import { AUTOCOMPLETE_DIRECT_MATCH_INDEX_ID, SEARCH_SUGGESTIONS_INDEX_ID } from 'bundles/search-common/constants';
import { MAX_SUGGESTION_COUNT } from 'bundles/search-common/constants/autocomplete';
import 'bundles/search-common/constants/filter';
import type { SearchConfig } from 'bundles/search-common/providers/searchTypes';

export const useAutocompleteSearchConfigs = (): SearchConfig[] => {
  const configs: SearchConfig[] = [
    {
      id: SEARCH_SUGGESTIONS_INDEX_ID,
      entityType: 'SUGGESTIONS',
      limit: MAX_SUGGESTION_COUNT,
      disableRecommender: true,
    },
    {
      id: AUTOCOMPLETE_DIRECT_MATCH_INDEX_ID,
      entityType: 'PRODUCTS',
      limit: 1,
      disableRecommender: true,
    },
  ];

  return configs;
};

export default useAutocompleteSearchConfigs;
