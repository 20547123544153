/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import SimplifiedMegaMenuSection from 'bundles/megamenu/components/sections/SimplifiedMegaMenuSection';

export type Props = {
  menuIsOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  shouldShowCourseraPlusButton?: boolean;
};
const headerHeight = '65px';

const styles = {
  simplifiedMegaMenuOverlay: css`
    position: fixed;
    background-color: rgba(0 0 0 / 20%);
    width: 100vw;
    height: calc(100% - ${headerHeight});
    display: block;
    opacity: 1;
  `,
  simplifiedMegaMenuContainer: css`
    position: fixed;
    width: 100%;
    max-height: calc(100vh - ${headerHeight});
    overflow: auto;
  `,
  simplifiedMegaMenuContentContainer: css`
    border-top: 1px solid rgba(0 0 0 / 12%);
    background-color: var(--cds-color-white-0);
    padding-top: var(--cds-spacing-200);
  `,
};

export const SimplifiedMegaMenuContainer = (props: Props) => {
  const { menuIsOpen, openMenu, closeMenu, shouldShowCourseraPlusButton } = props;

  return (
    <div data-testid="simplified-megaMenu-container" aria-hidden={!menuIsOpen} css={styles.simplifiedMegaMenuOverlay}>
      <div css={styles.simplifiedMegaMenuContainer} onMouseEnter={openMenu} onMouseLeave={closeMenu}>
        <div css={styles.simplifiedMegaMenuContentContainer}>
          <SimplifiedMegaMenuSection isMobile={false} shouldShowCourseraPlusButton={shouldShowCourseraPlusButton} />
        </div>
      </div>
    </div>
  );
};

export default SimplifiedMegaMenuContainer;
