import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';

import { find, isEqual, keyBy } from 'lodash';

import { useRetracked } from 'js/lib/retracked';
import useRouter from 'js/lib/useRouter';

import { useTracker } from '@coursera/event-pulse/react';

import { DEFAULT_SORTED_VALUE, NUMBER_OF_RESULTS_PER_PAGE } from 'bundles/search-common/constants';
import { SEARCH_PROVIDER_DEFAULT_CONTEXT_VALUE } from 'bundles/search-common/constants/provider';
import { SearchContext } from 'bundles/search-common/providers/SearchContext';
import type {
  SearchConfig,
  SearchResult,
  SearchResults,
  SearchState,
  SearchStates,
  SearchURLParameters,
  StateUpdates,
} from 'bundles/search-common/providers/searchTypes';
import useSearchParameters from 'bundles/search-common/providers/useSearchParameters';
import useSearchProviderQuery from 'bundles/search-common/providers/useSearchProviderQuery';
import type { SearchSortType } from 'bundles/search-common/types';
import { manipulateFiltersForEventing } from 'bundles/search-common/utils/providerEventingUtils';
import {
  addFiltersToFacetFilters,
  clearFacetFilters,
  combineConfigWithUrlParam,
  removeFiltersFromFacetFilters,
  setFiltersForFacet,
  syncSearchStateToUrl,
} from 'bundles/search-common/utils/providerUtils';

type Props = {
  searchConfigs: SearchConfig[];
  children: ReactNode;
  shouldReplaceRoute?: boolean;
  ssr?: boolean;
};

function SearchProviderInternal({ searchConfigs, children, shouldReplaceRoute = false, ssr = true }: Props) {
  const logEvent = useRetracked();
  const params = useSearchParameters();
  const searchStatesHashMap = keyBy(combineConfigWithUrlParam(searchConfigs, params), 'id');
  const [searchStates, setSearchStates] = useState<SearchStates>(searchStatesHashMap);
  const prevParams = useRef<SearchURLParameters | undefined>(params);
  const { isLoading, error, results, loadMore } = useSearchProviderQuery(searchStates, searchConfigs, ssr);

  const router = useRouter();
  const track = useTracker();

  const replaceSearchStates = React.useCallback<(a: SearchState[]) => void>(
    (newSearchStates: SearchState[]) => {
      let shouldUpdateSearchState = false;
      let shouldSyncSearchStateToUrl = false;
      const updatedSearchStates = { ...(searchStates || {}) };
      for (const newSearchState of newSearchStates) {
        const searchConfigId = newSearchState.id;
        const existSearchState = find(searchStates, (s: SearchState) => s.id === searchConfigId);
        if (existSearchState) {
          const { syncQueryWithUrl, syncFiltersWithUrl } = existSearchState;
          if (syncQueryWithUrl || syncFiltersWithUrl) shouldSyncSearchStateToUrl = true;
          if (!isEqual(existSearchState, newSearchState)) {
            shouldUpdateSearchState = true;
            updatedSearchStates[searchConfigId] = newSearchState;
          }
        }
      }
      // update searchStates altogether
      if (shouldUpdateSearchState) setSearchStates(updatedSearchStates);
      // sync url after state to prevent race condition
      if (shouldSyncSearchStateToUrl) syncSearchStateToUrl(router, newSearchStates[0], shouldReplaceRoute);
    },
    [router, searchStates, shouldReplaceRoute]
  );
  const replaceSearchState = (searchConfigId: string, newSearchState: SearchState) => {
    const existSearchState = find(searchStates, (s: SearchState) => s.id === searchConfigId);
    if (existSearchState) {
      const newSearchStates = { ...(searchStates || {}) };
      const { syncQueryWithUrl, syncFiltersWithUrl } = existSearchState;
      newSearchStates[searchConfigId] = newSearchState;
      setSearchStates(newSearchStates);
      if (syncQueryWithUrl || syncFiltersWithUrl) {
        syncSearchStateToUrl(router, newSearchState, shouldReplaceRoute);
      }
    }
  };

  useEffect(() => {
    if (!isEqual(prevParams.current, params)) {
      // update state according to URL param change
      replaceSearchStates(combineConfigWithUrlParam(searchConfigs, params));
    }

    prevParams.current = params;
  }, [params, replaceSearchStates, searchConfigs, searchStatesHashMap]);

  const updateQuery = (query: string) => {
    const newSearchStates = Object.keys(searchStates).map((indexId) => ({
      ...searchStates[indexId],
      query,
    }));
    replaceSearchStates(newSearchStates);

    logEvent({
      action: 'search',
      trackingName: 'update_query',
      trackingData: { query },
    });
  };

  const updateQueryForIndex = (id: string, query: string) => {
    const newSearchState: SearchState = { ...searchStates[id], query, page: undefined };
    replaceSearchState(id, newSearchState);

    logEvent({
      action: 'search',
      trackingName: 'update_query',
      trackingData: { id, query },
    });
  };

  const addFilters = (id: string, filters: string[]) => {
    if (filters.length < 1) return;

    const newSearchState: SearchState = { ...searchStates[id], page: undefined };
    newSearchState.facetFilters = addFiltersToFacetFilters(filters, newSearchState.facetFilters);
    replaceSearchState(id, newSearchState);

    track('perform_search', {
      searchedQuery: newSearchState.query,
      filter: manipulateFiltersForEventing(newSearchState.facetFilters),
    });

    logEvent({
      action: 'search',
      trackingName: 'add_filters',
      trackingData: { id, filters },
    });
  };

  const setFiltersByFacet = (id: string, facet: string, filters: string[]) => {
    const newSearchState: SearchState = { ...searchStates[id], page: undefined };
    newSearchState.facetFilters = setFiltersForFacet(facet, filters, newSearchState.facetFilters);

    replaceSearchState(id, newSearchState);

    track('perform_search', {
      searchedQuery: newSearchState.query,
      filter: manipulateFiltersForEventing(newSearchState.facetFilters),
    });

    logEvent({
      action: 'search',
      trackingName: 'apply_filters',
      trackingData: { id, filters },
    });
  };

  const removeFilters = (id: string, filters: string[]) => {
    const newSearchState: SearchState = { ...searchStates[id], page: undefined };
    if (newSearchState.facetFilters) {
      newSearchState.facetFilters = removeFiltersFromFacetFilters(filters, newSearchState.facetFilters);
      replaceSearchState(id, newSearchState);
    }

    track('perform_search', {
      searchedQuery: newSearchState.query,
      filter: manipulateFiltersForEventing(newSearchState.facetFilters),
    });

    logEvent({
      action: 'search',
      trackingName: 'remove_filters',
      trackingData: { id, filters },
    });
  };

  const clearFilters = (id: string) => {
    const newSearchState: SearchState = {
      ...searchStates[id],
      facetFilters: clearFacetFilters(searchStates[id]?.facetFilters),
      page: undefined,
    };

    replaceSearchState(id, newSearchState);

    track('perform_search', {
      searchedQuery: newSearchState.query,
      filter: manipulateFiltersForEventing(newSearchState.facetFilters),
    });

    logEvent({
      action: 'search',
      trackingName: 'clear_filters',
      trackingData: { id },
    });
  };

  const setPage = (id: string, page: number) => {
    const newSearchState: SearchState = { ...searchStates[id], page };

    replaceSearchState(id, newSearchState);

    logEvent({
      action: 'search',
      trackingName: 'set_page',
      trackingData: { id, page },
    });
  };

  const loadNextPage = () => {
    const id = Object.keys(searchStates)[0]; // id of the main config
    const inferredNextPage = Math.floor(
      (results?.find((s) => s.id === id)?.elements?.length || NUMBER_OF_RESULTS_PER_PAGE) / NUMBER_OF_RESULTS_PER_PAGE
    );

    if (loadMore) {
      loadMore(inferredNextPage);
      logEvent({
        action: 'search',
        trackingName: 'set_page',
        trackingData: { id, page: inferredNextPage },
      });
    }
  };

  const setSortBy = (id: string, sortBy: SearchSortType) => {
    const newSearchState: SearchState = { ...searchStates[id], sortBy, page: undefined };
    replaceSearchState(id, newSearchState);
    logEvent({
      action: 'search',
      trackingName: 'set_sort_by',
      trackingData: { sortBy },
    });
  };

  const applySearchStateUpdates = (id: string, searchStateUpdates: StateUpdates) => {
    const updates = Object.keys(searchStateUpdates);
    const newSearchState: SearchState = { ...searchStates[id] };

    if (updates.includes('query')) {
      newSearchState.query = searchStateUpdates.query;
      logEvent({
        action: 'search',
        trackingName: 'update_query',
        trackingData: { query: searchStateUpdates.query },
      });
    }

    if (updates.includes('clearFilters') && searchStateUpdates.clearFilters) {
      newSearchState.facetFilters = clearFacetFilters(newSearchState?.facetFilters);
      logEvent({
        action: 'search',
        trackingName: 'clear_filters',
        trackingData: { id },
      });
    }

    if (updates.includes('addFilters') && searchStateUpdates.addFilters) {
      newSearchState.facetFilters = addFiltersToFacetFilters(
        searchStateUpdates.addFilters,
        newSearchState.facetFilters
      );
      logEvent({
        action: 'search',
        trackingName: 'add_filters',
        trackingData: { id, filters: searchStateUpdates.addFilters },
      });
    }

    if (updates.includes('removeFilters') && searchStateUpdates.removeFilters) {
      newSearchState.facetFilters = removeFiltersFromFacetFilters(
        searchStateUpdates.removeFilters,
        newSearchState.facetFilters || []
      );
      logEvent({
        action: 'search',
        trackingName: 'remove_filters',
        trackingData: { id, filters: searchStateUpdates.removeFilters },
      });
    }

    if (updates.includes('setFiltersByFacet') && searchStateUpdates.setFiltersByFacet) {
      Object.keys(searchStateUpdates.setFiltersByFacet).forEach((facet) => {
        const filters = searchStateUpdates.setFiltersByFacet?.[facet];
        if (filters) newSearchState.facetFilters = setFiltersForFacet(facet, filters, newSearchState.facetFilters);
        logEvent({
          action: 'search',
          trackingName: 'apply_filters',
          trackingData: { id, filters },
        });
      });
    }

    if (updates.includes('sortBy')) {
      newSearchState.sortBy = searchStateUpdates.sortBy;
      logEvent({
        action: 'search',
        trackingName: 'set_sort_by',
        trackingData: { sortBy: searchStateUpdates.sortBy },
      });
    }

    if (updates.includes('page')) {
      newSearchState.page = searchStateUpdates.page;
      logEvent({
        action: 'search',
        trackingName: 'set_page',
        trackingData: { id, page: searchStateUpdates.page },
      });
    }

    replaceSearchState(id, newSearchState);

    track('perform_search', {
      searchedQuery: newSearchState.query,
      filter: manipulateFiltersForEventing(newSearchState.facetFilters),
    });

    if (
      updates.includes('clearFilters') &&
      updates.includes('sortBy') &&
      searchStateUpdates.sortBy === DEFAULT_SORTED_VALUE
    ) {
      logEvent({
        action: 'reset',
        trackingName: 'clear_filters_and_sort_by',
        trackingData: { id },
      });
    }
  };

  function getSearchResults(): SearchResults | undefined;
  function getSearchResults(id: string): SearchResult | undefined;
  function getSearchResults(id?: string) {
    if (id) {
      return results?.find((s) => s.id === id);
    } else {
      return results;
    }
  }

  return (
    <SearchContext.Provider
      value={{
        getSearchResults,
        isLoading,
        error,
        updateQuery,
        updateQueryForIndex,
        addFilters,
        removeFilters,
        clearFilters,
        setPage,
        setFiltersByFacet,
        setSortBy,
        applySearchStateUpdates,
        loadNextPage,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

function SearchProvider({ children, ...props }: Props) {
  // initiate provider with default value so other hooks (e.g. useSearchProviderQuery) can check if provider is defined
  return (
    <SearchContext.Provider value={SEARCH_PROVIDER_DEFAULT_CONTEXT_VALUE}>
      <SearchProviderInternal {...props}>{children}</SearchProviderInternal>
    </SearchContext.Provider>
  );
}

export default SearchProvider;
