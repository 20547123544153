import requestCountry from 'js/lib/requestCountry';

import localStorageEx from 'bundles/common/utils/localStorageEx';

declare const COURSERA_APP_NAME: string;

const EXCLUDED_APPS_FOR_LEGAL_CONSOLIDATION_AUTH_FLOW = [
  'front-page',
  'consumer-home',
  'onboarding-2022',
  'authentication',
  'enterprise-home',
  'enterprise-description-page',
];

const isExcludedAppForLegalConsolidationAuthFlow = () => {
  const appName = COURSERA_APP_NAME;
  return EXCLUDED_APPS_FOR_LEGAL_CONSOLIDATION_AUTH_FLOW.includes(appName);
};

// Utility function to determine when to show the terms of use acceptance flow this is for all US users
export const isTermsOfUseRolloutForUS = () => {
  return requestCountry.get() === 'US';
};

// Utility function to check if the legal consolidation auth flow should be shown to the user
export const shouldShowLegalConsolidationAuthFlow = () => {
  return requestCountry.get() === 'US' && !isExcludedAppForLegalConsolidationAuthFlow();
};

// Utility function to check if the checkbox terms of use should be shown in the signup form
export const shouldShowCheckboxTermsOfUse = () => {
  return !shouldShowLegalConsolidationAuthFlow() && isTermsOfUseRolloutForUS();
};

export const shouldShowTOSDisclaimer = () => !shouldShowCheckboxTermsOfUse() && !shouldShowLegalConsolidationAuthFlow();

// For automation tests like E2E, we need to bypass the bot manager flow, except for test cases that include Arkose.
export const shouldBypassBotManagerForAutomationTest = () =>
  localStorageEx.getItem('shouldBypassBotManager', Boolean, false);

export default {
  shouldShowCheckboxTermsOfUse,
  isTermsOfUseRolloutForUS,
};
