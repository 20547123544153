/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Avatar, Tag } from '@coursera/cds-core';
import { ChevronDownIcon, ChevronUpIcon } from '@coursera/cds-icons';

import { previewShouldShowPostPurchaseChanges } from 'bundles/coursera-plus/utils/subscriptionsForwardDiscoveryExperiment';

import _t from 'i18n!nls/page';

type Props = {
  user: {
    full_name: string;
    photo_120: string;
  };
  showCaret?: boolean;
  isCaretUp?: boolean;
  isCourseraPlusLearner?: boolean;
};

const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
  `,
  chevron: css`
    margin-left: var(--cds-spacing-50);
  `,
  border: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -10px;
  `,
  avatarWrapper: css`
    &.cds-Avatar-showBorder {
      border: 2px solid var(--cds-color-interactive-primary);
    }
  `,
  plusBadge: css`
    padding: 0 6px;
    position: relative;
    top: -10px;
    height: 10px;

    span {
      font-size: 9px;
    }

    &.cds-tag-status.cds-tag-primary.cds-tag-default {
      background-color: var(--cds-color-interactive-primary);
      border-color: var(--cds-color-interactive-primary);
    }
  `,
};

const UserPortraitV2 = ({ user, showCaret, isCaretUp, isCourseraPlusLearner }: Props) => {
  const { full_name: fullName, photo_120: photoUrl } = user;
  const firstName = fullName && fullName.split(' ')[0];
  const showCPlus = previewShouldShowPostPurchaseChanges();
  const inCPlusPromoExperiment = isCourseraPlusLearner && showCPlus;

  let chevron;
  if (showCaret && isCaretUp) {
    chevron = <ChevronUpIcon data-testid="svg-chevron-up" size="small" color="default" css={styles.chevron} />;
  } else {
    chevron = <ChevronDownIcon data-testid="svg-chevron-down" size="small" color="default" css={styles.chevron} />;
  }

  return (
    <div className="pii-hide" css={styles.wrapper}>
      <div css={inCPlusPromoExperiment && styles.border}>
        <Avatar
          variant="user"
          data-testid="user-avatar"
          initial={firstName?.[0]}
          imgProps={{ src: photoUrl, alt: _t('Profile image for #{learnerName}', { learnerName: fullName }) }}
          css={inCPlusPromoExperiment && styles.avatarWrapper}
          showBorder={inCPlusPromoExperiment}
        />
        {inCPlusPromoExperiment && (
          <Tag variant="status" priority="primary" css={styles.plusBadge}>
            PLUS
          </Tag>
        )}
      </div>
      {chevron}
    </div>
  );
};

export default UserPortraitV2;
