/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import type { FocusEvent } from 'react';
import * as React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import type { LegacyContextType } from 'types/legacy-context-types';

import Retracked from 'js/app/retracked';
import createLoadableComponent from 'js/lib/createLoadableComponent';
import { isUserRightToLeft } from 'js/lib/language';
import user, { isAuthenticatedUser } from 'js/lib/user';

import { Grid, Link, PageGridContainer } from '@coursera/cds-core';

import { MetaNavigation, getMetaHeight, isMetaNavEnabled } from 'bundles/ent-website/components/MetaNavigation';
import {
  COURSERA,
  NOT_SELECTED,
  PROGRAM,
} from 'bundles/enterprise-legacy-learner-home/constants/SwitcherSelectionTypes';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type Degrees from 'bundles/naptimejs/resources/degrees.v1';
import HeaderLogo from 'bundles/page-header/components/HeaderLogo';
import HeaderRightNav from 'bundles/page-header/components/HeaderRightNavV2';
import type { PropsFromCaller as HeaderRightNavProps } from 'bundles/page-header/components/HeaderRightNavV2';
import type { Course } from 'bundles/page-header/components/PageHeader';
import SmartScrollWrapper from 'bundles/page-header/components/SmartScrollWrapper';
import BrowseContentWrapper from 'bundles/page-header/components/desktop/BrowseContentWrapper';
import type { SwitcherSelectionType } from 'bundles/page-header/components/mobile/constants';
import PageHeaderContext from 'bundles/page-header/contexts/PageHeaderContext';
import {
  isAuthoringPathname,
  populateWithDegreesAndProgramsOnClientside,
} from 'bundles/page-header/utils/pageHeaderNavUtils';
import { currentPathnameOf, userAuthenticatedIn } from 'bundles/page/lib/migration';
import type {
  GetS12nCertificateBannerProps,
  ProductDiscountPromoBannerProps,
  Program,
} from 'bundles/page/types/Program';
import { showBanner } from 'bundles/promotions/utils/productDiscountPromoBannerUtils';

import _t from 'i18n!nls/page-header';

import 'css!bundles/page-header/components/__styles__/PageHeader';

const LoadableSwitcherPanel = createLoadableComponent(
  () => import('bundles/page-header/components/switcher/SwitcherPanel')
);

const LoadableGDPRBanner = createLoadableComponent(() => import('bundles/user-consent/components/GDPRBanner'));

const LoadableProductDiscountPromoBanner = createLoadableComponent(
  () => import('bundles/promotions/components/ProductDiscountPromoBanner')
);

const LoadableGetS12nCertificateBanner = createLoadableComponent(
  () => import('bundles/enroll/components/common/GetS12nCertificateBanner')
);

const LoadableEnrollmentStateBanner = createLoadableComponent(
  () => import('bundles/preview/containers/EnrollmentStateBanner')
);

const LoadableEnterpriseReturnToProgramBanner = createLoadableComponent(
  () => import('bundles/enterprise-legacy-learner-home/components/EnterpriseReturnToProgramBanner')
);

const LoadableProgramSearchBar = createLoadableComponent(
  // FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
  // eslint-disable-next-line import/no-cycle
  () => import('bundles/page-header/components/desktop/program-search-bar/ProgramSearchBar')
);

const HEADER_SEARCH_BAR_BLACKLISTED_PATHNAMES: string[] = [];

type SearchBarParamsType = PropsToComponent;

export type SlugType = 'courseSlug' | 's12nSlug';

export type PropsFromCaller = {
  course?: Course;
  itemId?: string;
  showLanguagesDropdown?: boolean;
  shouldSkipOptionalExternalDataFetch?: boolean;
  hideRightNav?: boolean;
  hideSearch?: boolean;
  hideMetaNav?: boolean;
  hideMembershipSwitcher?: boolean;
  hideEnterprise?: boolean;
  hasCatalogButton?: boolean;
  isEnterprise?: boolean;
  initialSearchText?: string;
  isInNewCatalog?: boolean;
  showShoppingCart?: boolean;
  hideNotificationCenter?: boolean;
  isHeaderFixedTop?: boolean;
  disableHeaderLogoUserInteraction?: boolean;
  enableCourseraHeaderLogoOnly?: boolean;
  mainSearchInputSelector?: string;
  thirdPartyOrganizationId?: string;
  affiliateElement?: React.ReactElement | null;
  productDiscountPromoBannerProps?: ProductDiscountPromoBannerProps;
  getS12nCertificateBannerProps?: GetS12nCertificateBannerProps;
  containerHeight?: number;
  children?: JSX.Element;
  showGDPRBanner?: boolean;
  isScrollable?: boolean;
  showEnterpriseLogo?: boolean;
  showEnterpriseReturnToProgramBanner?: boolean;
  switcherSelections?: SwitcherSelectionType;
  logoWrapper?: string;
  isSearchPage?: boolean;
  logoQueryParams?: Record<string, string | undefined>;
  showExploreCatalog?: boolean;
  injectedSearchBar?: JSX.Element | null;
  isOrgHome?: boolean;
  hideLogIn?: boolean;
  hideDropdownOptions?: boolean;
  pageHeaderClassnameOverride?: string;
};

type PropsToComponent = PropsFromCaller & {
  programs: Array<Program>;
  degrees: Degrees;
  programLogoDisplay?: Program;
};

type HeightPlaceholder = {
  isInit: boolean;
  bonusClassName: string;
};

type State = {
  showSwitcherPanel: boolean;
  heightPlaceholders: Record<string, HeightPlaceholder>;
  isMounted: boolean;
  showSkipToContent: boolean;
  isMainContentContainerAvailable: boolean;
  isLoggedInHome: boolean;
};

const styles = {
  pageHeaderContainer: css`
    display: flex;
    align-items: center;
  `,
  pageHeaderContainerOld: css`
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;

    &::before,
    &::after {
      display: table;
      content: ' ';
    }

    &::after {
      clear: both;
    }
  `,
  headerRightNavWrapper: css`
    margin-left: auto;
  `,
  searchBar: css`
    max-width: 600px !important;
    flex-shrink: 0;
    flex: 1;
  `,
  programSearchBar: css`
    width: 100%;
    max-width: 600px;
  `,
  navbarFixedTopContainer: css`
    .bt3-navbar-fixed-top {
      right: 0;
      left: 0;
      top: 0;
      border-width: 0 0 1px;
    }

    @media (min-width: 768px) {
      .bt3-navbar-fixed-top {
        border-radius: 0;
      }
    }
  `,
};

export class DesktopHeaderControls extends React.Component<PropsToComponent, State> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    _eventData: PropTypes.object,
  };

  declare context: LegacyContextType<typeof DesktopHeaderControls.contextTypes>;

  declare interval: number;

  declare intervalCount: number;

  static defaultProps = {
    hideRightNav: false,
    hideSearch: false,
    hideEnterprise: false,
    isInNewCatalog: false,
    hasCatalogButton: true,
    initialSearchText: '',
    showShoppingCart: true,
    hideNotificationCenter: false,
    isHeaderFixedTop: false,
    containerHeight: 70,
    disableHeaderLogoUserInteraction: false,
    isScrollable: false,
    showEnterpriseLogo: false,
    hideDropdownOptions: false,
  };

  state: State = {
    showSwitcherPanel: false,
    heightPlaceholders: {},
    isMounted: false,
    showSkipToContent: false,
    isMainContentContainerAvailable: false,
    isLoggedInHome: false,
  };

  componentDidMount() {
    const isMounted = true;
    const isLoggedInHome = window.appName === 'logged-in-home';
    this.interval = window.setInterval(this.checkMainContentContainer, 500); // Check every 500ms
    this.intervalCount = 0;

    this.setState(() => ({ isMounted, isLoggedInHome }));
  }

  checkMainContentContainer = () => {
    // in order to mitigate dynamic main content loading
    const isMainContentContainerAvailable = !!document.getElementById('main');
    this.intervalCount += 1;
    if (isMainContentContainerAvailable) {
      this.setState({ isMainContentContainerAvailable });
      clearInterval(this.interval); // Clear the interval once the element is found
    }
    if (this.intervalCount > 40) {
      clearInterval(this.interval); // Clear the interval after 20 seconds
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleProgramSwitcherToggle = () => {
    const { _eventData } = this.context;
    const { showSwitcherPanel, isMounted } = this.state;
    if (isMounted) {
      this.setState({ showSwitcherPanel: !showSwitcherPanel });
      Retracked.trackComponent(_eventData, {}, 'switcher_dropdown', 'click');
    }
  };

  handleSkipToMainContent = (event: FocusEvent<HTMLElement>) => {
    const { type } = event;
    if (type === 'focus') {
      this.setState({
        showSkipToContent: true,
      });
    } else if (type === 'blur') {
      this.setState({
        showSkipToContent: false,
      });
    }
  };

  addHeightClass = (className: string, bonusClassName?: string) => {
    this.setState(
      ({ heightPlaceholders }) => ({
        heightPlaceholders: {
          ...heightPlaceholders,
          [className]: {
            isInit: heightPlaceholders[className]?.isInit ?? false,
            bonusClassName: bonusClassName ?? '',
          },
        },
      }),
      () => setTimeout(this.addHeightClassCallback, 0, className)
    );
  };

  addHeightClassCallback = (className: string) => {
    this.setState(({ heightPlaceholders }) => ({
      heightPlaceholders: {
        ...heightPlaceholders,
        [className]: {
          ...heightPlaceholders[className],
          isInit: true,
        },
      },
    }));
  };

  removeHeightClass = (className: string) => {
    this.setState(({ heightPlaceholders }) => ({
      heightPlaceholders: {
        ...heightPlaceholders,
        [className]: {
          ...heightPlaceholders[className],
          isInit: false,
          bonusClassName: '',
        },
      },
    }));
  };

  forceHideSearch = () => {
    if (userAuthenticatedIn(this)) {
      return false;
    } else {
      const currentPathname = currentPathnameOf(this);
      const isBlacklistedPath = HEADER_SEARCH_BAR_BLACKLISTED_PATHNAMES.indexOf(currentPathname) !== -1;
      return isBlacklistedPath;
    }
  };

  // TODO: https://coursera.atlassian.net/browse/WWG-94 refactor component, add unit tests to improve readability and maintainability
  render() {
    const {
      switcherSelections,
      programs,
      degrees,
      course,
      thirdPartyOrganizationId,
      affiliateElement,
      mainSearchInputSelector,
      hideRightNav,
      hideSearch,
      hideMetaNav,
      hideEnterprise,
      disableHeaderLogoUserInteraction,
      enableCourseraHeaderLogoOnly,
      productDiscountPromoBannerProps,
      getS12nCertificateBannerProps,
      isEnterprise,
      isScrollable,
      showGDPRBanner,
      showEnterpriseLogo,
      showEnterpriseReturnToProgramBanner,
      isHeaderFixedTop,
      logoWrapper,
      showShoppingCart,
      hideNotificationCenter,
      logoQueryParams,
      showExploreCatalog,
      hideMembershipSwitcher,
      injectedSearchBar,
      isOrgHome,
      programLogoDisplay,
      hideLogIn,
      showLanguagesDropdown,
      itemId,
      hideDropdownOptions,
      pageHeaderClassnameOverride,
    } = this.props;

    const {
      heightPlaceholders,
      showSwitcherPanel,
      isMounted,
      showSkipToContent,
      isMainContentContainerAvailable,
      isLoggedInHome,
    } = this.state;

    const initialSearchBarParams = {};

    const searchBarParams: SearchBarParamsType = Object.assign(initialSearchBarParams, this.props);

    const navClassnames = classNames('bt3-navbar', 'c-ph-nav', 'full-width', {
      'bt3-navbar-fixed-top': isHeaderFixedTop,
    });

    const skipToContentClassnames = classNames('align-items-vertical-center', 'horizontal-box', {
      'sr-only': !showSkipToContent,
      'skip-content-main': showSkipToContent,
    });

    let shouldShowSearch = true;

    if (programs && switcherSelections) {
      const noDegreeOrProgram = _.isEmpty(degrees) && _.isEmpty(programs);
      const noSelection =
        switcherSelections.selectionType === COURSERA || switcherSelections.selectionType === NOT_SELECTED;
      shouldShowSearch = noDegreeOrProgram || noSelection || isLoggedInHome;
    }

    let currentThirdPartyOrganizationId = thirdPartyOrganizationId;
    if (!currentThirdPartyOrganizationId) {
      if (switcherSelections && switcherSelections.selectionType === PROGRAM) {
        const program = _.find(programs, ({ id }: Program) => id === switcherSelections.programId);

        currentThirdPartyOrganizationId = program?.thirdPartyOrganizationId;
      }
    }

    const [wrapperClass, headerClass, smartScrollStyle] = [
      'rc-DesktopHeaderControls',
      'rc-PageHeader',
      { boxShadow: 'none', maxWidth: '100vw' },
    ];

    const currentProgram = _.find(programs, (program) => {
      return !program ? false : program.id === switcherSelections?.programId;
    });

    const currentDegree = _.find(degrees, (degree) => {
      return !degree ? false : degree.id === switcherSelections?.degreeId;
    });

    const showProductDiscountPromoBanner = showBanner(this.props);

    const showGetS12nCertificateBanner = getS12nCertificateBannerProps && getS12nCertificateBannerProps.s12nSlug;

    const { router } = this.context;
    let displaySwitcherFlag: boolean | undefined;
    let showAdminLinks;
    let showSearch;
    const { location } = router;
    const { pathname } = location;

    const isAdminOrTeachPage = isAuthoringPathname(pathname);
    const hideSearchBar = isAdminOrTeachPage || hideSearch || this.forceHideSearch();
    const showProgramSearch = !hideSearchBar && currentProgram && currentThirdPartyOrganizationId;

    /* For all routes under authoring, hide search bar, disable switcher */
    if (isAdminOrTeachPage || hideMembershipSwitcher) {
      displaySwitcherFlag = false;
      showSearch = false;
      showAdminLinks = true;
    } else {
      displaySwitcherFlag =
        user.get().is_staff ||
        ((!_.isEmpty(programs) || !_.isEmpty(degrees) || isEnterprise) && !hideRightNav && user.get().authenticated);
      showAdminLinks = false;
      showSearch =
        !isEnterprise && (!(currentProgram || currentDegree) || isLoggedInHome) && shouldShowSearch && !hideSearchBar;
    }

    let searchBar: React.ReactNode | null;

    if (showSearch) {
      searchBar = (
        <PageHeaderContext.Consumer>
          {({ isSimplifiedPageHeader, cPlusEntryPointsData }) => (
            <div
              className="c-ph-search-catalog nav-item horizontal-box browse-search"
              {...(isSimplifiedPageHeader ? { css: styles.searchBar } : {})}
            >
              <BrowseContentWrapper
                {...searchBarParams}
                className={mainSearchInputSelector}
                shouldShowCourseraPlusButton={
                  !(!isAuthenticatedUser() ? false : !cPlusEntryPointsData?.shouldShowCourseraPlusBanner) &&
                  !(programs.length || degrees.length)
                }
              />
            </div>
          )}
        </PageHeaderContext.Consumer>
      );
    } else if (showProgramSearch) {
      searchBar = (
        <div css={styles.programSearchBar}>
          <LoadableProgramSearchBar
            thirdPartyOrganizationId={currentThirdPartyOrganizationId as string}
            programId={currentProgram.id}
            programSlug={currentProgram.metadata?.slug}
            programName={currentProgram.metadata?.name}
            userId={user.get().id}
            enableSkillsInSearchAndBrowse={false}
            shouldShowShortFormContent={false}
          />
        </div>
      );
    } else {
      searchBar = null;
    }

    if (injectedSearchBar) {
      searchBar = <div className="c-ph-search-enterprise hidden-sm-down horizontal-box">{injectedSearchBar}</div>;
    }

    const headerRightNavProps: HeaderRightNavProps = {
      programs,
      degrees,
      showShoppingCart,
      hideNotificationCenter,
      hideEnterprise,
      isEnterprise,
      showAdminLinks,
      thirdPartyOrganizationId,
      showExploreCatalog,
      hideLogIn,
      showLanguagesDropdown,
      itemId,
      courseId: course?.id,
      hideDropdownOptions,
      currentProgram,
    };

    const metaNavEnabled = isMetaNavEnabled({ forceOverride: hideMetaNav ? false : undefined });

    const desktopHeaderControls = (
      <PageHeaderContext.Consumer>
        {({ isSimplifiedPageHeader, subNavigationLinks: pageNavigation }) => {
          return (
            <div
              className={classNames(
                headerClass,
                metaNavEnabled ? 'rc-PageHeader--MetaNav' : null,
                isSimplifiedPageHeader ? 'rc-PageHeader--PageNavigation' : null
              )}
              key="nav"
              data-e2e="page-header"
            >
              {metaNavEnabled && !isSimplifiedPageHeader && <MetaNavigation isFluid isCustomGrid />}
              <div css={styles.navbarFixedTopContainer} className={navClassnames}>
                <div
                  className={classNames('c-container')}
                  {...(isSimplifiedPageHeader
                    ? { css: styles.pageHeaderContainer }
                    : { css: styles.pageHeaderContainerOld })}
                >
                  <div className={skipToContentClassnames}>
                    {isMainContentContainerAvailable && (
                      <div className="skip-button-outer-div">
                        <div className="align-items-vertical-center align-items-absolute-center">
                          <Link
                            href="#main"
                            onFocus={this.handleSkipToMainContent}
                            onBlur={this.handleSkipToMainContent}
                          >
                            {_t('Skip to main content')}
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="header-logo-wrapper"
                    style={{ float: isUserRightToLeft() ? 'right' : 'left' }}
                    data-testid="header-logo"
                  >
                    <HeaderLogo
                      course={course}
                      program={currentProgram}
                      degree={currentDegree}
                      thirdPartyOrganizationId={currentThirdPartyOrganizationId}
                      affiliateElement={affiliateElement}
                      hexColorCode={
                        disableHeaderLogoUserInteraction && enableCourseraHeaderLogoOnly
                          ? ''
                          : 'var(--cds-color-interactive-primary)'
                      }
                      disableUserInteraction={disableHeaderLogoUserInteraction}
                      enableCourseraLogoOnly={enableCourseraHeaderLogoOnly}
                      handleProgramSwitcherToggle={this.handleProgramSwitcherToggle}
                      isOpened={showSwitcherPanel}
                      displaySwitcher={displaySwitcherFlag}
                      showEnterpriseLogo={showEnterpriseLogo}
                      logoWrapper={logoWrapper}
                      logoQueryParams={logoQueryParams}
                      isAdminOrTeachPage={isAdminOrTeachPage}
                      isOrgHome={isOrgHome}
                      programLogoDisplay={programLogoDisplay}
                      isLoggedInHome={isLoggedInHome}
                      programs={programs}
                      degrees={degrees}
                    />
                  </div>
                  {searchBar}
                  {!hideRightNav && (
                    <div
                      className="header-right-nav-wrapper"
                      {...(isSimplifiedPageHeader ? { css: styles.headerRightNavWrapper } : {})}
                      style={{
                        minWidth: '250px',
                        float: isUserRightToLeft() ? 'left' : 'right',
                      }}
                      data-testid="header-right-nav"
                    >
                      <HeaderRightNav {...headerRightNavProps} />
                    </div>
                  )}
                </div>
                {!hideRightNav && showSwitcherPanel && (
                  <LoadableSwitcherPanel
                    selectedCoursera={switcherSelections && switcherSelections.selectionType === COURSERA}
                    currentProgram={currentProgram}
                    currentDegree={currentDegree}
                    programs={programs}
                    degrees={degrees}
                    handleProgramSwitcherToggle={this.handleProgramSwitcherToggle}
                  />
                )}
              </div>
              {pageNavigation}
            </div>
          );
        }}
      </PageHeaderContext.Consumer>
    );

    /*
      ProductDiscountPromoBanner applies to all product pages (XDP/CDP/SDP)
      The banner is only visible after the user visits a promo landing page and is on an applicable product page.
    */
    const productDiscountPromoBanner = showProductDiscountPromoBanner && (
      <LoadableProductDiscountPromoBanner
        {...productDiscountPromoBannerProps}
        addParentHeightClass={this.addHeightClass}
        removeParentHeightClass={this.removeHeightClass}
      />
    );
    /*
      GetS12nCertificateBanner applies to only the SDP
      The banner is only visible when the user has already earned the s12n certificate by completing a superset s12n.
    */
    const getS12nCertificateBanner = showGetS12nCertificateBanner && (
      <LoadableGetS12nCertificateBanner
        {...getS12nCertificateBannerProps}
        addParentHeightClass={this.addHeightClass}
        removeParentHeightClass={this.removeHeightClass}
      />
    );

    // switcherSelections switches from undefined => Array when gql has fetched.
    const gdprBanner = showGDPRBanner && switcherSelections && (
      <LoadableGDPRBanner
        addParentHeightClass={this.addHeightClass}
        removeParentHeightClass={this.removeHeightClass}
        programs={programs}
      />
    );

    const enterpriseReturnToProgramBanner =
      showEnterpriseReturnToProgramBanner && user.isAuthenticatedUser() && currentProgram ? (
        <LoadableEnterpriseReturnToProgramBanner
          userId={user.get().id}
          program={currentProgram}
          pathname={pathname}
          addParentHeightClass={this.addHeightClass}
          removeParentHeightClass={this.removeHeightClass}
        />
      ) : undefined;

    const pageHeaderContent = (
      <PageHeaderContext.Consumer>
        {({ isSimplifiedPageHeader }) => {
          const content = (
            <React.Fragment>
              {desktopHeaderControls}
              {productDiscountPromoBanner}
              {gdprBanner}
              {getS12nCertificateBanner}
              {enterpriseReturnToProgramBanner}
            </React.Fragment>
          );
          return isSimplifiedPageHeader ? (
            <React.Fragment>
              {metaNavEnabled && <MetaNavigation />}
              <PageGridContainer>
                <Grid xs={12} item>
                  {content}
                </Grid>
              </PageGridContainer>
            </React.Fragment>
          ) : (
            content
          );
        }}
      </PageHeaderContext.Consumer>
    );

    const pageHeader = isScrollable ? (
      <div className="smart-scroll-container">{pageHeaderContent}</div>
    ) : (
      <div className="smart-scroll-container">
        <SmartScrollWrapper
          delta={metaNavEnabled ? 50 : Number.MAX_VALUE}
          containerHeight={parseInt(getMetaHeight(), 10)}
          style={smartScrollStyle}
        >
          {pageHeaderContent}
        </SmartScrollWrapper>
      </div>
    );

    return (
      <PageHeaderContext.Consumer>
        {({ subNavigationLinks: pageNavigation, isSimplifiedPageHeader }) => {
          return (
            <div>
              <header
                className={classNames(
                  wrapperClass,
                  metaNavEnabled ? 'rc-DesktopHeaderControls--MetaNav' : null,
                  isSimplifiedPageHeader ? 'rc-DesktopHeaderControls--PageNavigation' : null,
                  pageHeaderClassnameOverride
                )}
                data-catchpoint="page-header-controls"
              >
                {pageHeader}
                {!isScrollable && (
                  <div className="height-placeholders" role="presentation">
                    <div
                      className={classNames(
                        'height-placeholder',
                        'with-desktop-header-controls',
                        metaNavEnabled ? 'with-desktop-header-controls--MetaNav' : null,
                        pageNavigation ? 'with-desktop-header-controls--PageNavigation' : null
                      )}
                    />
                    {_.map(heightPlaceholders, ({ isInit, bonusClassName }, className) => (
                      <div
                        key={className}
                        className={classNames('height-placeholder', isInit && className, isInit && bonusClassName)}
                        role="presentation"
                      />
                    ))}
                  </div>
                )}
              </header>
              {isMounted && userAuthenticatedIn(this) && (
                <LoadableEnrollmentStateBanner
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ addParentHeightClass: (className: string, ... Remove this comment to see the full error message
                  addParentHeightClass={this.addHeightClass}
                  removeParentHeightClass={this.removeHeightClass}
                  data-catchpoint="page-header-controls"
                />
              )}
            </div>
          );
        }}
      </PageHeaderContext.Consumer>
    );
  }
}

export default compose<PropsToComponent, PropsFromCaller>(populateWithDegreesAndProgramsOnClientside())(
  DesktopHeaderControls
);
