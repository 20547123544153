const DEFAULT_PAGE_NUMBER = '1';
export function inferPageNumberFromParams(currentPageFromParams: string | string[] | null | undefined): string {
  const pageNumber = Array.isArray(currentPageFromParams) ? currentPageFromParams[0] : currentPageFromParams;
  if (pageNumber === '' || pageNumber === null) return DEFAULT_PAGE_NUMBER; // because Number('' | null) === 0
  const number = Number(pageNumber);
  return isNaN(number) || number < 0 ? DEFAULT_PAGE_NUMBER : String(number);
}
export function inferQueryFromParams(queryFromParams: string | string[] | null | undefined): string {
  return String((Array.isArray(queryFromParams) ? queryFromParams[0] : queryFromParams) || '');
}
