import * as React from 'react';

import classNames from 'classnames';
import URI from 'jsuri';
import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';

import logger from 'js/app/loggerSingleton';
import Multitracker from 'js/app/multitrackerSingleton';
import user from 'js/lib/user';

import AuthenticationV3CtaTracking from 'bundles/authentication/shared/AuthenticationV3CtaTracking';
import GoogleID from 'bundles/authentication/shared/components/GoogleID';
import { getShouldShowCourseraPlusEntrypointInNav } from 'bundles/coursera-plus/utils/subscriptionsForwardDiscoveryExperiment';
import HeaderRightNavButton from 'bundles/page-header/components/HeaderRightNavButton';
import PageNavLink from 'bundles/page-header/components/page-navigation/PageNavLink';
import PageHeaderContext from 'bundles/page-header/contexts/PageHeaderContext';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { getCareerAcademyLabel, getCareerAcademyLink } from 'bundles/page/components/shared/utils/careerAcademyUtils';
import { AUTH_MODES } from 'bundles/user-account/common/constants';

import _t from 'i18n!nls/page';

type ValidAuthMode = keyof Pick<typeof AUTH_MODES, 'signup' | 'login'>;

type PropsFromCaller = {
  className: string;
  hideEnterprise?: boolean;
  q2ShowDesktopAltSignupLabel?: () => void;
  isEnterprise?: boolean;
  isStandaloneAuth?: boolean;
  experimentalStyles?: boolean;
  hideLogIn?: boolean;
  showUnifiedLinks?: boolean;
};
class UnauthenticatedAccountInfo extends React.Component<PropsFromCaller, { componentDidMount: boolean }> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    getStore: PropTypes.func,
  };

  declare context: LegacyContextType<typeof UnauthenticatedAccountInfo.contextTypes>;

  state = {
    componentDidMount: false,
  };

  componentDidMount() {
    this.setState(() => ({ componentDidMount: true }));
  }

  getLinkClassNames = (authMode: ValidAuthMode, wrapperClassName: $TSFixMe) => {
    const { hideEnterprise } = this.props;
    return classNames('c-ph-right-nav-button', wrapperClassName, {
      'c-ph-right-nav-no-border': authMode === AUTH_MODES.signup || hideEnterprise,
    });
  };

  getCombinedQueryWithAuthMode = (authMode: ValidAuthMode) => {
    const {
      router: {
        location: { query },
      },
    } = this.context;
    return Object.assign({}, query, { authMode });
  };

  getFullPageRedirectTo = () => {
    const {
      router: {
        location: { query, pathname, search },
      },
    } = this.context;

    const fullPath = search ? pathname + search : pathname;
    const redirectTo = query?.redirectTo || query?.r || fullPath || '/';

    return redirectTo;
  };

  /**
   * @param  {String} authMode signup|login
   */
  getAuthButtonHref = (authMode: ValidAuthMode) => {
    const { isStandaloneAuth } = this.props;
    if (isStandaloneAuth) {
      const href = new URI()
        .setPath(`/${authMode}`)
        .addQueryParam('redirectTo', this.getFullPageRedirectTo())
        .toString();
      return href;
    } else {
      if (typeof window === 'undefined') {
        logger.error('Cannot get auth button href without window');
        return '/?authMode=signup';
      }

      return new URI(window.location.href).addQueryParam('authMode', authMode).toString();
    }
  };

  /**
   * @param  {String} authMode signup|login
   */
  bringUpUserModal = (authMode: ValidAuthMode) => {
    if (user.isAuthenticatedUser()) {
      return true;
    }

    if (authMode === AUTH_MODES.login) {
      Multitracker.pushV2(['open_course.user.login_modal.open']);
    } else if (authMode === AUTH_MODES.signup) {
      Multitracker.pushV2(['open_course.user.signup_modal.open']);
    }

    return false;
  };

  renderAuthorizationLinks = (isSimplifiedPageHeader: boolean | undefined) => {
    const { hideEnterprise, isEnterprise, experimentalStyles, isStandaloneAuth, hideLogIn } = this.props;
    const { componentDidMount } = this.state;
    const {
      router: {
        location: { pathname, hash },
      },
    } = this.context;
    if (!pathname) {
      logger.error('pathname is required to render unauthenticated auth buttons');
      return false;
    }

    // (quang)
    // If the page is SSR, we simply return links for Login and Sign Up.
    // If the page is CSR, links would not work due to an issue with react-router soft redirect.
    // Therefore, we manually pop up legacy authorization modal as a work-around.
    // Afaik, the only CSR page that is visible for non logged-in page left is
    // the public lecture page (ex. https://www.coursera.org/learn/hipython/lecture/AgEre/2-ndarray)

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ssr' does not exist on type 'Window & ty... Remove this comment to see the full error message
    const csrEnabled = componentDidMount && !window.ssr && window.appName === 'ondemand' && !user.isAuthenticatedUser();

    const relAttr = pathname === '/' ? '' : 'nofollow';

    const itemClasses = {
      login: {
        wrapper: classNames({
          'authbutton-login': experimentalStyles,
          'join-btn': experimentalStyles,
          'c-ph-log-in': !experimentalStyles,
          isLohpRebrand: true,
        }),
      },
      signup: {
        wrapper: classNames({
          'authbutton-signup': experimentalStyles,
          'join-btn': experimentalStyles,
          'c-ph-sign-up': !experimentalStyles,
          'cta-color-exp': isSimplifiedPageHeader,
          'not-enterprise-cta': isSimplifiedPageHeader && !isEnterprise,
          isLohpRebrand: true,
        }),
        link: classNames('standardSignupBtnLink signup-jff-fp-btn', {
          'authlink-signup': experimentalStyles,
          'link-button': !experimentalStyles,
        }),
      },
      enterprise: {
        link: classNames({
          'enterpriselink-signup': experimentalStyles,
          'join-btn': !experimentalStyles,
          'link-button': !experimentalStyles,
          primary: !experimentalStyles,
          isLohpRebrand: true,
        }),
      },
    };

    const AUTH_BUTTON_DATA_LIST: Array<{
      label: string;
      authMode: ValidAuthMode;
      wrapperClassName?: string;
      linkClassName?: string;
    }> = [];

    if (!hideLogIn) {
      if (isEnterprise) {
        AUTH_BUTTON_DATA_LIST.push({
          label: _t('Log In'),
          authMode: AUTH_MODES.login,
          wrapperClassName: itemClasses.signup.wrapper,
          linkClassName: itemClasses.enterprise.link,
        });
      } else {
        AUTH_BUTTON_DATA_LIST.push({
          label: _t('Log In'),
          authMode: AUTH_MODES.login,
          wrapperClassName: itemClasses.login.wrapper,
        });
      }
    }

    // don't show "Join for free" for enterprise
    if (!isEnterprise) {
      AUTH_BUTTON_DATA_LIST.push({
        label: _t('Join for Free'),
        authMode: AUTH_MODES.signup,
        wrapperClassName: itemClasses.signup.wrapper,
        linkClassName: itemClasses.signup.link,
      });
    }

    return AUTH_BUTTON_DATA_LIST.map(({ label, authMode, linkClassName = '', wrapperClassName }) => {
      if (csrEnabled) {
        return (
          <AuthenticationV3CtaTracking>
            {(trackV3) => (
              <HeaderRightNavButton
                key={authMode}
                wrapperClassName={wrapperClassName}
                noBorder={authMode === AUTH_MODES.signup || hideEnterprise}
                label={label}
                href={this.getAuthButtonHref(authMode)}
                name={authMode}
                onClick={() => {
                  trackV3({ name: authMode, sectionName: 'page_header' });
                  this.bringUpUserModal(authMode);
                }}
                linkClassName={authMode === AUTH_MODES.signup ? `${linkClassName} primary` : linkClassName}
                linkRel={relAttr}
                data-e2e={authMode === AUTH_MODES.signup ? 'header-signup-button' : 'header-login-button'}
              />
            )}
          </AuthenticationV3CtaTracking>
        );
      } else {
        let href: string | { pathname: string; hash: string; query: string };
        if (isStandaloneAuth) {
          href = this.getAuthButtonHref(authMode);
        } else {
          const queryWithAuthMode = this.getCombinedQueryWithAuthMode(authMode);

          href = {
            pathname,
            hash,
            query: queryWithAuthMode,
          };
        }

        return (
          <li className={this.getLinkClassNames(authMode, wrapperClassName)} key={authMode}>
            <AuthenticationV3CtaTracking>
              {(trackV3) => (
                <TrackedLink2
                  href={href}
                  trackingName="header_right_nav_button"
                  data={{ name: authMode }}
                  className={authMode === AUTH_MODES.signup ? `${linkClassName} primary` : linkClassName}
                  rel={relAttr}
                  data-e2e={authMode === AUTH_MODES.signup ? 'header-signup-button' : 'header-login-button'}
                  onClick={() => trackV3({ name: authMode, sectionName: 'page_header' })}
                >
                  {label}
                </TrackedLink2>
              )}
            </AuthenticationV3CtaTracking>
          </li>
        );
      }
    });
  };

  renderInlineLinks = () => {
    return (
      <React.Fragment>
        <li className="c-ph-right-nav-button">
          <PageNavLink
            label={_t('Online Degrees')}
            name="online_degrees"
            href="/degrees"
            trackingName="logged_out_online_degrees"
          />
        </li>
        <li className="c-ph-right-nav-button">
          <PageNavLink
            label={getCareerAcademyLabel()}
            name="online_career"
            href={getCareerAcademyLink('globalnav')}
            trackingName="logged_out_career_academy"
          />
        </li>
      </React.Fragment>
    );
  };

  renderCourseraPlusLink = () => {
    return (
      <li className="c-ph-right-nav-button">
        <PageNavLink
          label={_t('Coursera Plus')}
          name="coursera_plus"
          href="/courseraplus"
          trackingName="logged_out_coursera_plus"
        />
      </li>
    );
  };

  render() {
    const { className, showUnifiedLinks } = this.props;
    const showCPlusLink = getShouldShowCourseraPlusEntrypointInNav();

    return (
      <React.Fragment>
        <GoogleID />
        <PageHeaderContext.Consumer>
          {({ isSimplifiedPageHeader }) => (
            <ul className={className}>
              {showUnifiedLinks && showCPlusLink && this.renderCourseraPlusLink()}
              {showUnifiedLinks && !showCPlusLink && this.renderInlineLinks()}
              {this.renderAuthorizationLinks(isSimplifiedPageHeader)}
            </ul>
          )}
        </PageHeaderContext.Consumer>
      </React.Fragment>
    );
  }
}

export default UnauthenticatedAccountInfo;
