/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { graphql } from 'react-apollo';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import classNames from 'classnames';
import gql from 'graphql-tag';
import { compose } from 'recompose';

import connectToRouter from 'js/lib/connectToRouter';
import createLoadableComponent from 'js/lib/createLoadableComponent';
import user from 'js/lib/user';
import type UserAgentInfo from 'js/lib/useragent';

import { Grid, PageGridContainer } from '@coursera/cds-core';

import { ExternalCoachLauncher } from 'bundles/ai-course-coach/components/Launcher';
import { CoachPermissionsWrapper } from 'bundles/ai-course-coach/utils/CoachPermissionWrapper';
import { getGlobalAppName } from 'bundles/ai-course-coach/utils/getGlobalAppName';
import { isCoachEnabledByApp } from 'bundles/ai-course-coach/utils/isCoachEnabledByApp';
import type SwitcherSelectionsType from 'bundles/naptimejs/resources/programSwitcherSelections.v1';
import ClientSideRenderedNotificationCenter from 'bundles/notification-center/components/ClientSideRenderedNotificationCenter';
import AuthenticatedAccountDropdown from 'bundles/page-header/components/AuthenticatedAccountDropdown';
import SmartScrollWrapper from 'bundles/page-header/components/SmartScrollWrapper';
import HeaderMobileLogo from 'bundles/page-header/components/mobile/HeaderMobileLogo';
import HeaderMobileSearchIcon from 'bundles/page-header/components/mobile/HeaderMobileSearchIcon';
import MobileHeaderNav from 'bundles/page-header/components/mobile/MobileHeaderNav';
import MobileSearchContainerLite from 'bundles/page-header/components/mobile/MobileSearchContainerLite';
import type {
  MobileHeaderProgramQuery,
  MobileHeaderProgramQueryVariables,
} from 'bundles/page-header/components/mobile/__generated__/MobileHeaderProgramQuery';
import { TRANSITION_DELAY } from 'bundles/page-header/components/mobile/constants';
import PageHeaderContext from 'bundles/page-header/contexts/PageHeaderContext';
import {
  isAuthoringPathname,
  populateWithDegreesAndProgramsOnClientside,
} from 'bundles/page-header/utils/pageHeaderNavUtils';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import type { GetS12nCertificateBannerProps, ProductDiscountPromoBannerProps } from 'bundles/page/types/Program';
import { showBanner } from 'bundles/promotions/utils/productDiscountPromoBannerUtils';

import 'css!bundles/page-header/components/mobile/__styles__/MobileHeader';

const ProductDiscountPromoBanner = createLoadableComponent(
  () => import('bundles/promotions/components/ProductDiscountPromoBanner')
);
const GetS12nCertificateBanner = createLoadableComponent(
  () => import('bundles/enroll/components/common/GetS12nCertificateBanner')
);

// Populate dropdown with degrees and programs data to
// ensure access to the dropdown menu when viewing the program/course home
export const AuthenticatedAccountDropdownWithData = populateWithDegreesAndProgramsOnClientside()(
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof AuthenticatedAccountDropd... Remove this comment to see the full error message
  AuthenticatedAccountDropdown
);

type State = {
  searchIsFocused: boolean;
  searchIsOpen: boolean;
  disableHideSearch: boolean;
  extraClassNames: Array<string>;
};

type PropsFromCaller = {
  toggleMobileCourseMenu?: () => void;
  isScrollable?: boolean;
  isSearchPage?: boolean;
  productDiscountPromoBannerProps?: ProductDiscountPromoBannerProps;
  getS12nCertificateBannerProps?: GetS12nCertificateBannerProps;
  showEnterpriseLogo?: boolean;
  hideNav?: boolean;
  hideSearch?: boolean;
  shouldSkipOptionalExternalDataFetch?: boolean;
  switcherSelections?: SwitcherSelectionsType;
  userAgent?: UserAgentInfo;
  pathname?: string;
  showAccountDropdown?: boolean;
  isEnterprise?: boolean;
  programId?: string;
  hideMobileNavLinks?: boolean;
  disableHeaderLogoUserInteraction?: boolean;
  injectedSearchBar?: React.ReactNode;
};

type PropsToComponent = PropsFromCaller & PropsFromGraphql;

export type ThirdPartyOrganization = {
  name?: string;
  squareLogo?: string | null;
  primaryColor?: string | null;
  iconColor?: string | null;
};

export type EnterpriseProgram = {
  name?: string;
  metadata?: { squareLogo?: string | null };
};

type PropsFromGraphql = {
  currentProgram: EnterpriseProgram;
  thirdPartyOrganization: ThirdPartyOrganization;
};

const disableScrolling = (disabled: boolean) => {
  const bodyStyle = document?.body?.style;
  if (bodyStyle) {
    bodyStyle.overflow = disabled ? 'hidden' : 'visible';
  }
};

const styles = {
  headerContainer: css`
    && {
      height: 113px;
    }
  `,
  header: css`
    border-bottom: 1px solid var(--cds-color-neutral-stroke-primary-weak);
  `,
  controlContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0;
  `,
  topHeader: css`
    display: flex;
  `,
  controls: css`
    display: flex;
    align-items: center;
    gap: 24px;
  `,
  controlsSimplified: css`
    display: flex;
    align-items: center;
  `,
  navContainer: css`
    .bt3-nav::before,
    .bt3-nav::after {
      content: ' ';
      display: table;
    }

    .bt3-nav::after {
      clear: both;
    }

    .bt3-nav {
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;
    }

    .bt3-nav > li {
      position: relative;
      display: block;
    }

    .bt3-nav > li > a {
      position: relative;
      display: block;
      padding: 10px 15px;
    }

    .bt3-nav > li > a:hover,
    .bt3-nav > li > a:focus {
      text-decoration: none;
      background-color: #eee;
    }

    .bt3-nav > li.bt3-disabled > a {
      color: #999;
    }

    .bt3-nav > li.bt3-disabled > a:hover,
    .bt3-nav > li.bt3-disabled > a:focus {
      color: #999;
      text-decoration: none;
      background-color: transparent;
      cursor: not-allowed;
    }

    .bt3-nav .bt3-open > a,
    .bt3-nav .bt3-open > a:hover,
    .bt3-nav .bt3-open > a:focus {
      background-color: #eee;
      border-color: '#0062E4';
    }
  `,
};

export class MobileHeader extends React.Component<PropsToComponent, State> {
  static defaultProps = {
    showEnterpriseLogo: false,
    hideNav: false,
    hideSearch: false,
  };

  state = {
    searchIsOpen: false,
    searchIsFocused: false,
    disableHideSearch: false,
    extraClassNames: [],
  };

  onSearchFocus = () => {
    this.setState({ searchIsFocused: true });
  };

  onSearchBlur = () => {
    this.setState({ searchIsFocused: false, disableHideSearch: true });
    setTimeout(() => this.setState({ disableHideSearch: false }), 10);
  };

  showSearch = () => {
    this.setState({ searchIsOpen: true, searchIsFocused: true });
    disableScrolling(true);
  };

  hideSearch = () => {
    const { disableHideSearch } = this.state;
    if (!disableHideSearch) {
      this.setState({ searchIsOpen: false });
      disableScrolling(false);
    }
  };

  addContainerClass = (extraClassName: string) => {
    this.setState(({ extraClassNames }) => ({
      extraClassNames: extraClassNames.includes(extraClassName)
        ? extraClassNames
        : [...extraClassNames, extraClassName],
    }));
  };

  removeContainerClass = (extraClassName: string) => {
    this.setState(({ extraClassNames }) => ({
      extraClassNames: extraClassNames.includes(extraClassName)
        ? extraClassNames.filter((extraClassName0) => extraClassName0 !== extraClassName)
        : extraClassNames,
    }));
  };

  renderControls() {
    const {
      userAgent,
      isSearchPage,
      toggleMobileCourseMenu,
      hideNav,
      hideSearch,
      showEnterpriseLogo,
      shouldSkipOptionalExternalDataFetch,
      switcherSelections,
      showAccountDropdown,
      pathname,
      isEnterprise,
      thirdPartyOrganization,
      currentProgram,
      hideMobileNavLinks,
      disableHeaderLogoUserInteraction,
      injectedSearchBar,
    } = this.props;

    const { searchIsOpen } = this.state;

    const shouldShowSearchIcon = !searchIsOpen && !hideSearch && !isSearchPage;
    const isStaff = user.get().is_staff || user.get().is_superuser;
    const fill = searchIsOpen ? '#2A73CC' : '#1f1f1f';

    const controlsClassNames = classNames(
      'c-mobile-header-controls horizontal-box isLohpRebrand',
      'align-items-spacebetween',
      { 'with-account-dropdown': showAccountDropdown }
    );

    const isAdminOrTeachPage = isAuthoringPathname(pathname);
    const dropdownProps = {
      isStaff,
      showAdminLinks: isAdminOrTeachPage,
      showFullName: !showAccountDropdown,
      label: '',
      hideAvatarBorder: true,
      isMobile: true,
    };
    // excluding XDP / learn route from showing the coach in global header. this will eventually go away after the AB test
    const appName = getGlobalAppName();
    const coachIncludedByRoute = isCoachEnabledByApp(appName);

    const controls = (
      <PageHeaderContext.Consumer>
        {({ isSimplifiedPageHeader }) => (
          <React.Fragment>
            <div css={isSimplifiedPageHeader ? styles.controlsSimplified : styles.controls}>
              <CSSTransitionGroup
                transitionName="fade"
                transitionEnterTimeout={TRANSITION_DELAY * 2}
                transitionLeaveTimeout={TRANSITION_DELAY}
              >
                {!searchIsOpen && !hideNav && (
                  <MobileHeaderNav
                    userAgent={userAgent}
                    toggleMobileCourseMenu={toggleMobileCourseMenu}
                    shouldSkipOptionalExternalDataFetch={shouldSkipOptionalExternalDataFetch}
                    switcherSelections={switcherSelections}
                    isEnterprise={isEnterprise}
                    thirdPartyOrganization={thirdPartyOrganization}
                    currentProgram={currentProgram}
                    hideMobileNavLinks={hideMobileNavLinks}
                  />
                )}
              </CSSTransitionGroup>
              <HeaderMobileLogo
                showEnterpriseLogo={showEnterpriseLogo}
                showAccountDropdown={showAccountDropdown}
                isAdminOrTeachPage={isAdminOrTeachPage}
                thirdPartyOrganization={thirdPartyOrganization}
                currentProgram={currentProgram}
                disableHeaderLogoUserInteraction={disableHeaderLogoUserInteraction}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ClientSideRenderedNotificationCenter />
              <CSSTransitionGroup
                className="iconContainer"
                transitionName="fade"
                transitionEnterTimeout={TRANSITION_DELAY * 2}
                transitionLeaveTimeout={TRANSITION_DELAY}
              >
                {shouldShowSearchIcon &&
                  (isSimplifiedPageHeader && injectedSearchBar ? (
                    injectedSearchBar
                  ) : (
                    <HeaderMobileSearchIcon key="icon" fill={fill} onClick={this.showSearch} />
                  ))}
              </CSSTransitionGroup>
              {coachIncludedByRoute && (
                <CoachPermissionsWrapper>
                  {({ showEmbeddedCoach }) => {
                    if (showEmbeddedCoach) {
                      return <ExternalCoachLauncher />;
                    }
                    return null;
                  }}
                </CoachPermissionsWrapper>
              )}
              {!searchIsOpen && showAccountDropdown && (
                <ul
                  id="authenticated-mobile-info-menu"
                  className="bt3-nav c-navbar-list custom-mobile-header"
                  role="none"
                >
                  <AuthenticatedAccountDropdownWithData {...dropdownProps} />
                </ul>
              )}
            </div>
          </React.Fragment>
        )}
      </PageHeaderContext.Consumer>
    );

    return (
      <PageHeaderContext.Consumer>
        {({ subNavigationLinks: pageNavigation, isSimplifiedPageHeader }) => {
          if (!isSimplifiedPageHeader) {
            return <div className={controlsClassNames}>{controls}</div>;
          }
          return (
            <PageGridContainer css={styles.header}>
              <Grid item xs={12}>
                <div css={styles.controlContainer}>{controls}</div>
                {pageNavigation}
              </Grid>
            </PageGridContainer>
          );
        }}
      </PageHeaderContext.Consumer>
    );
  }

  render() {
    const { searchIsFocused, searchIsOpen, extraClassNames } = this.state;
    const { isSearchPage, isScrollable, productDiscountPromoBannerProps, getS12nCertificateBannerProps } = this.props;
    const showProductDiscountPromoBanner = showBanner(this.props);
    const showGetS12nCertificateBanner = getS12nCertificateBannerProps && getS12nCertificateBannerProps.s12nSlug;

    const wrapperClass = classNames('rc-MobileHeader rc-MobileHeaderLite', ...extraClassNames);

    return (
      <div css={styles.navContainer}>
        <PageHeaderContext.Consumer>
          {({ isSimplifiedPageHeader, subNavigationLinks }) => (
            <TrackedDiv
              trackingName="mobile_header_lite"
              className={wrapperClass}
              trackClicks={false}
              withVisibilityTracking
              {...(isSimplifiedPageHeader && subNavigationLinks ? { css: styles.headerContainer } : {})}
            >
              {isScrollable ? (
                // Static header
                this.renderControls()
              ) : (
                // Sticky header

                <SmartScrollWrapper
                  delta={Number.MAX_VALUE}
                  containerHeight={65}
                  style={{ minWidth: '200px', maxWidth: '100vw', boxShadow: 'none', zIndex: 3000 }}
                >
                  {this.renderControls()}
                  {showProductDiscountPromoBanner && (
                    <ProductDiscountPromoBanner
                      {...productDiscountPromoBannerProps}
                      addParentHeightClass={this.addContainerClass}
                      removeParentHeightClass={this.removeContainerClass}
                    />
                  )}
                  {/*
                GetS12nCertificateBanner applies to only the SDP
                The banner is only visible when the user has already earned the s12n certificate by completing a superset s12n.
              */}
                  {showGetS12nCertificateBanner && (
                    <GetS12nCertificateBanner
                      {...getS12nCertificateBannerProps}
                      addParentHeightClass={this.addContainerClass}
                      removeParentHeightClass={this.removeContainerClass}
                    />
                  )}
                </SmartScrollWrapper>
              )}
              <MobileSearchContainerLite
                isSearchPage={isSearchPage}
                searchIsOpen={searchIsOpen}
                searchIsFocused={searchIsFocused}
                onBlur={this.onSearchBlur}
                onFocus={this.onSearchFocus}
                hideMobileSearchPage={this.hideSearch}
              />
            </TrackedDiv>
          )}
        </PageHeaderContext.Consumer>
      </div>
    );
  }
}

export default compose<PropsToComponent, PropsFromCaller>(
  connectToRouter(({ location: { pathname } }) => {
    return {
      pathname,
    };
  }),
  graphql<PropsFromCaller, MobileHeaderProgramQuery, MobileHeaderProgramQueryVariables, PropsFromGraphql>(
    gql`
      query MobileHeaderProgramQuery($programId: String!) {
        EnterpriseProgramsV1Resource {
          get(id: $programId) {
            id
            metadata {
              name
              squareLogo
            }
            thirdPartyOrg {
              id
              name
              squareLogo
              primaryColor
              iconColor
            }
          }
        }
      }
    `,
    {
      skip: ({ programId }) => !programId,
      options: ({ programId }) => ({
        variables: {
          programId: programId || '',
        },
        ssr: false,
      }),
      props: ({ data }) => {
        const thirdPartyOrganizationData = data?.EnterpriseProgramsV1Resource?.get?.thirdPartyOrg;
        const programMetadata = data?.EnterpriseProgramsV1Resource?.get?.metadata;

        return {
          currentProgram: {
            name: programMetadata?.name,
            metadata: { squareLogo: programMetadata?.squareLogo },
          },
          thirdPartyOrganization: {
            name: thirdPartyOrganizationData?.name,
            squareLogo: thirdPartyOrganizationData?.squareLogo,
            primaryColor: thirdPartyOrganizationData?.primaryColor,
            iconColor: thirdPartyOrganizationData?.iconColor,
          },
        };
      },
    }
  )
)(MobileHeader);
