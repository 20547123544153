import { css } from '@emotion/react';

import * as React from 'react';

import initBem from 'js/lib/bem';

import { Grid, PageGridContainer, Typography2, VisuallyHidden } from '@coursera/cds-core';

import BCorpLogo from 'bundles/page-footer/components/BCorpLogo';
import CopyrightV2 from 'bundles/page-footer/components/seo-sub-footer/CopyrightV2';
import SocialMediaLinksV2 from 'bundles/page-footer/components/seo-sub-footer/SocialMediaLinksV2';
import SubFooterSection from 'bundles/page-footer/components/seo-sub-footer/SubFooterSection';
import { getGlobalFooterSectionData } from 'bundles/page-footer/components/seo-sub-footer/constants';
import type {
  GlobalFooterSectionData,
  SubFooterSectionData,
} from 'bundles/page-footer/components/seo-sub-footer/types';
import MobileAppBadges from 'bundles/page/components/MobileAppBadges';
import { useSeoPageData } from 'bundles/seo/contexts/SeoPageDataContext';

import _t from 'i18n!nls/page';

import 'css!./__styles__/SeoGlobalFooter';

type PropsFromCaller = {
  isStickyBarPresent?: boolean;
};

type PropsToComponent = PropsFromCaller;

const bem = initBem('SeoGlobalFooter');

const styles = {
  bottomDesktop: css`
    margin: auto;
  `,
};

const SeoGlobalFooter = ({ isStickyBarPresent }: PropsToComponent) => {
  const subfooterRootClass = bem('', '', `lohp-rebrand${isStickyBarPresent ? ' stickyBar' : ''}`);
  const contentContainerClasses = bem('content', '', 'lohp-rebrand');
  const { subfooter, globalFooterLinksForCategory } = useSeoPageData();

  return (
    <footer className={subfooterRootClass} data-e2e="page-footer" data-catchpoint="page-footer">
      <VisuallyHidden>
        <Typography2 variant="titleSmall" component="p">
          {_t('Coursera Footer')}
        </Typography2>
      </VisuallyHidden>
      <PageGridContainer>
        <Grid container className={contentContainerClasses}>
          {subfooter?.map(({ key, ...restSectionData }: SubFooterSectionData) => {
            return <SubFooterSection key={key || restSectionData.header} {...restSectionData} />;
          })}
          {getGlobalFooterSectionData(globalFooterLinksForCategory).map(
            ({ key, ...restSectionData }: GlobalFooterSectionData) => {
              return <SubFooterSection key={key || restSectionData.header} {...restSectionData} />;
            }
          )}
          <Grid item sm={3}>
            <div className={bem('mobile-badges-column-text')}>{_t('Learn Anywhere')}</div>
            <MobileAppBadges justifyContentBetween isOnSubfooter />
            <BCorpLogo />
          </Grid>
          <Grid item sm={12} css={styles.bottomDesktop}>
            <div className={bem('social-media-and-copyright', '', 'lohp-rebrand')}>
              <CopyrightV2 />
              <SocialMediaLinksV2 />
            </div>
          </Grid>
        </Grid>
      </PageGridContainer>
    </footer>
  );
};

export default React.memo(SeoGlobalFooter);
