import { cloneDeep } from 'lodash';

import type { InjectedRouter } from 'js/lib/connectToRouter';

import {
  NUMBER_OF_RESULTS_PER_PAGE,
  SEARCH_FILTERS_INDEX_ID,
  SEARCH_MAIN_INDEX_ID,
  SEARCH_STATE_URL_PARAMS,
} from 'bundles/search-common/constants';
import {
  IS_PART_OF_COURSERA_PLUS,
  SEARCH_FILTER_PARAMS_NOT_SUPPORTED_BY_ALGOLIA,
} from 'bundles/search-common/constants/filter';
import type {
  SearchConfig,
  SearchQuery,
  SearchRequest,
  SearchRequests,
  SearchResultAugments,
  SearchResultRaw,
  SearchResults,
  SearchState,
  SearchStates,
  SearchURLParameters,
} from 'bundles/search-common/providers/searchTypes';
import { isInFilterTestVariant } from 'bundles/search-common/utils/experimentUtils';
import { consolidateFilters, getUserDefinedFacetFilters } from 'bundles/search-common/utils/utils';

function updateUrl(router: InjectedRouter, newPath: string, shouldReplaceRoute: boolean) {
  if (shouldReplaceRoute) {
    router.replace(newPath);
  } else {
    router.push(newPath);
  }
}

function filtersToKeyValuesMap(filters: string[]) {
  let obj: Record<string, string> = {};
  filters.forEach((filter) => {
    if (filter) {
      const [filterName, ...values] = filter.split(':');
      const value = values.join(':').replace(/^:+/, ''); // 1. join if values contains colon 2. remove colon at the start of string
      obj = { ...obj, [filterName]: value };
    }
  });
  return obj;
}

function syncSearchStateToUrl(router: InjectedRouter, searchState: SearchState, shouldReplaceRoute: boolean) {
  const { query, page, sortBy } = searchState;
  const newQueryParams = new URLSearchParams();
  if (searchState.syncQueryWithUrl && query) newQueryParams.append('query', query);

  if (searchState.syncFiltersWithUrl) {
    searchState.facetFilters?.forEach((filters) => {
      filters?.forEach((filter) => {
        // do not sync negative filters to url
        if (filter.includes(':-')) return;
        const [filterName, ...values] = filter.split(':');
        const value = values.join(':').replace(/^:+/, '');
        newQueryParams.append(filterName, value);
      });
    });

    if (page != null) newQueryParams.append('page', `${page}`);
    if (sortBy != null) newQueryParams.append('sortBy', `${sortBy}`);
  }

  // keep other non search params
  const allParams = router.location.query;
  Object.keys(allParams)?.forEach((key) => {
    if (!SEARCH_STATE_URL_PARAMS.includes(key) && !SEARCH_FILTER_PARAMS_NOT_SUPPORTED_BY_ALGOLIA.includes(key)) {
      newQueryParams.append(key, allParams[key]);
    }
  });

  let newPath = `${router.location.pathname ?? ''}?${newQueryParams.toString()}`;

  // URLSearchParams.append() encodes all spaces with plus (+) sign
  // this is being replaced by the %20 sign to match the canonical url.
  if (newPath && /\+/.test(newPath)) {
    newPath = newPath.replace(/\+/g, '%20');
  }

  updateUrl(router, newPath, shouldReplaceRoute);
}

function removeEmptyOrDuplicateFacetFilters(filters: string[][]): string[][] {
  // Remove duplicates and empty strings within each filter group
  // this is a pure function where results are deterministic
  return filters
    .map((filterGroup: string[]) => {
      const uniqueNonEmptyFilters = [...new Set(filterGroup)].filter((filter) => filter !== '');
      uniqueNonEmptyFilters.sort(); // Sort filters within each group
      return uniqueNonEmptyFilters;
    })
    .filter((filterGroup: string[]) => filterGroup.length > 0) // Remove empty arrays
    .sort((a: string[], b: string[]) => a[0].localeCompare(b[0])) // Sort filter groups based on the first element of each group
    .map((group) => JSON.stringify(group)) // Convert each group to a JSON string for comparison
    .filter((group, index, self) => self.indexOf(group) === index) // Remove duplicate groups
    .map((group) => JSON.parse(group)); // Convert back to arrays
}

function combineFacetFilters(configFilters: string[][] = [], paramFilters: string[][] = []): string[][] {
  const combinedFacetFilters = JSON.parse(JSON.stringify(configFilters));
  const paramFiltersCopy = JSON.parse(JSON.stringify(paramFilters)); // Deep copy

  paramFiltersCopy.forEach((paramFilter: string[]) => {
    if (paramFilter.length > 0) {
      const paramFilterKey = paramFilter[0].split(':')[0];
      const isNegativeFilter = paramFilter[0].includes(':-');
      const matchingConfigFilterIndex = combinedFacetFilters.findIndex(
        (configFilter: string[]) =>
          configFilter.length > 0 &&
          configFilter[0].split(':')[0] === paramFilterKey &&
          configFilter[0].includes(':-') === isNegativeFilter
      );

      if (matchingConfigFilterIndex !== -1 && !isNegativeFilter) {
        combinedFacetFilters[matchingConfigFilterIndex].push(...paramFilter);
      } else {
        combinedFacetFilters.push(paramFilter);
      }
    }
  });

  return removeEmptyOrDuplicateFacetFilters(combinedFacetFilters);
}

function sortFilterGroup(filterGroup: string[]) {
  return filterGroup.sort();
}

function combineConfigWithUrlParam(searchConfigs: SearchConfig[], params: SearchURLParameters): SearchState[] {
  return searchConfigs.map((config) => {
    let newConfig: SearchState = { ...config, maxValuesPerFacet: config.maxValuesPerFacet || 1000 };
    if (config.syncQueryWithUrl) newConfig.query = params.query;
    if (config.syncFiltersWithUrl) {
      const combinedFacetFilters = combineFacetFilters(config.facetFilters, params.facetFilters);

      // Filter out filters that contain ":-"
      const filteredCombinedFilters = combinedFacetFilters
        .map((filterGroup) => filterGroup.filter((filter) => !filter.includes(':-')))
        .filter((filterGroup) => filterGroup.length > 0); // Remove empty arrays

      // Ensure sorting is still correct after filter operation
      const sortedFilteredCombinedFilters = filteredCombinedFilters.map(sortFilterGroup);

      newConfig = {
        ...config,
        ...params,
        maxValuesPerFacet: config.maxValuesPerFacet || 1000,
        facetFilters: sortedFilteredCombinedFilters,
      };
    }
    newConfig.facetFilters = newConfig.facetFilters?.map((filterGroup) => filterGroup.sort());
    return newConfig;
  });
}

function addFiltersToFacetFilters(filters: string[], facetFilters?: string[][]): string[][] {
  // clone array so useSearchQuery refetch
  const newFacetFilters = facetFilters ? [...facetFilters] : [];

  // Map facet names to their index in the newFacetFilters array
  const facetToIndex: Record<string, number> = {};
  newFacetFilters.forEach((facet: string[], index: number) => {
    // and remove filters that excludes because it won't work without the separation
    if (facet?.[0]?.includes(':-')) return;
    facetToIndex[facet?.[0]?.split(':')[0]] = index;
  });

  // Add filters to corresponding facet or create a new facet
  filters.forEach((filter) => {
    const colonIndex = filter.indexOf(':');
    const facetName = filter.slice(0, colonIndex);
    const facetValue = filter.slice(colonIndex + 1);
    const filterToAdd = `${facetName}:${facetValue}`;

    if (facetToIndex[facetName] !== undefined) {
      // If facet exists, check if the filter is already present in it
      const existingFilters = newFacetFilters[facetToIndex[facetName]];
      if (existingFilters.indexOf(filterToAdd) === -1) {
        // making sure to deep clone so useSearchQuery refetch
        newFacetFilters[facetToIndex[facetName]] = [...newFacetFilters[facetToIndex[facetName]], filterToAdd];
      }
    } else {
      // If facet does not exist, create a new one
      newFacetFilters.push([filterToAdd]);
      facetToIndex[facetName] = newFacetFilters.length - 1;
    }
  });

  return newFacetFilters;
}

function removeFiltersFromFacetFilters(filters: string[], facetFilters: string[][]): string[][] {
  return facetFilters.map((fs) => fs.filter((f) => !filters.includes(f))).filter((fs) => fs.length > 0);
}

function setFiltersForFacet(facet: string, filters: string[], facetFilters?: string[][]): string[][] {
  // clone array so useSearchQuery refetch
  const newFacetFilters = facetFilters ? [...facetFilters] : [];

  // find the index of the filter[] to be replaced
  const indexOfToBeReplacedFilters = newFacetFilters.findIndex((_facet) => _facet[0]?.startsWith(facet));

  // replace or push filter[]
  if (indexOfToBeReplacedFilters > -1) {
    newFacetFilters[indexOfToBeReplacedFilters] = filters;
  } else {
    newFacetFilters.push(filters);
  }

  return newFacetFilters;
}

function clearFacetFilters(facetFilters?: string[][]): string[][] {
  // clone array so useSearchQuery refetch
  const newFacetFilters: string[][] = [];

  if (!isInFilterTestVariant()) {
    facetFilters?.forEach((filters) => {
      const filtered = filters.filter((item) => item.includes(IS_PART_OF_COURSERA_PLUS));
      if (filtered && filtered.length > 0) newFacetFilters.push(filtered);
    });
  }

  return newFacetFilters;
}

const mapStateToQuery = (searchState: SearchState): SearchRequest => {
  const { page, query, id: _id, ...rest } = searchState;
  const SearchStateCopy = { ...rest };
  // intentionally filtering the FE defined variables (including id) to prevents API error
  delete SearchStateCopy.syncQueryWithUrl;
  delete SearchStateCopy.syncFiltersWithUrl;
  delete SearchStateCopy.disableFacetFilterHack;

  return { ...SearchStateCopy, cursor: page ? `${page - 1}` : '0', query: query || '' };
};

export function getAugmentedProperties(rawResult: SearchResultRaw): SearchResultAugments {
  const hitsDisplayedCount = rawResult.elements?.length || 0;
  const hitsTotalCount = rawResult.pagination?.totalElements || 0;
  const inferredCurrentPageNumber = hitsDisplayedCount ? Math.ceil(hitsDisplayedCount / NUMBER_OF_RESULTS_PER_PAGE) : 1;
  return { hitsDisplayedCount, hitsTotalCount, inferredCurrentPageNumber };
}

const combineResultsWithStates = (
  searchStates: SearchState[],
  results?: SearchResultRaw[]
): SearchResults | undefined => {
  return results?.map((rawResult, index) => {
    return {
      ...searchStates[index],
      ...rawResult,
      facets: rawResult.facets,
      ...getAugmentedProperties(rawResult),
    };
  });
};

function removeNegativeFilters(results: SearchResults | undefined, searchConfigs: SearchConfig[]) {
  return results?.map((result, index) => {
    const config = searchConfigs[index];
    const negativeFilters = config?.facetFilters
      ?.filter((filter) => filter[0].includes(':-'))
      .map((filter) => filter[0].split(':-')[1]);

    if (result?.facets && negativeFilters) {
      const newFacets = result.facets.map((facet) => {
        if (facet?.valuesAndCounts) {
          const newValuesAndCounts = facet.valuesAndCounts.filter(
            (valueAndCount) => !negativeFilters.includes(valueAndCount.value)
          );
          return { ...facet, valuesAndCounts: newValuesAndCounts };
        }
        return facet;
      });
      return { ...result, facets: newFacets };
    }
    return result;
  });
}

const doFiltersNeedMerging = (data: SearchQuery | undefined, searchStatesArray?: SearchState[]) => {
  return data?.SearchResult && data?.SearchResult?.search?.length > (searchStatesArray?.length ?? 0);
};

/**
 * Combines existing search states with the new results. If there are more results than search states,
 * (i.e. we have multiple filters' query results), then we need to consolidate the results into a single
 * index. This is because the API returns a separate response for each filter, but the UI expects the results
 * to appear within a single index.
 */
const mergeFiltersAndStateWithResults = (
  data: SearchQuery | undefined,
  searchStatesArray: SearchState[],
  searchStates: SearchStates,
  filterCategory: string[],
  searchConfigs: SearchConfig[]
) => {
  let results;
  if (data?.SearchResult && doFiltersNeedMerging(data, searchStatesArray)) {
    const correctedFilters: SearchResultRaw = consolidateFilters(
      searchStates,
      data?.SearchResult?.search.slice(searchStatesArray.length),
      data.SearchResult.search.slice(0, searchStatesArray.length),
      filterCategory
    );
    results = combineResultsWithStates(searchStatesArray, [
      ...data?.SearchResult?.search?.slice(0, searchStatesArray.length - 1),
      correctedFilters,
    ]);
  } else {
    results = combineResultsWithStates(
      searchStatesArray,
      data?.SearchResult?.search?.slice(0, searchStatesArray.length)
    );
  }

  // remove negative filters facets from search UI
  return removeNegativeFilters(results, searchConfigs);
};

const getQueryParamsFromSearchRequests = (searchConfigs: SearchConfig[], searchStatesArray: SearchState[]) => {
  // Moves main index to first index if its not there
  const sortedStatesArray = searchStatesArray.slice().sort((a, b) => {
    if (a.id === SEARCH_MAIN_INDEX_ID) {
      return -1;
    } else if (b.id === SEARCH_MAIN_INDEX_ID) {
      return 1;
    } else if (a.id === SEARCH_FILTERS_INDEX_ID) {
      return 1;
    } else if (b.id === SEARCH_FILTERS_INDEX_ID) {
      return -1;
    }
    return 0;
  });

  const mappedQueryParams: SearchRequests = sortedStatesArray?.map((searchState) => {
    const searchConfigFound = searchConfigs.find((config) => config.id === searchState.id);
    const searchRequest = mapStateToQuery(searchState);
    // combine with the initial facetFilters for case like enterpriseIndexConfig clips
    if (searchConfigFound)
      searchRequest.facetFilters = combineFacetFilters(searchRequest.facetFilters, searchConfigFound.facetFilters);
    return searchRequest;
  });
  // Create a copy of the queryParams
  return [...mappedQueryParams];
};

const getFilterCategory = (allSearchRequestQueryParams: SearchRequest[]) => {
  const allSearchRequestQueryParamsCopy = cloneDeep(allSearchRequestQueryParams);
  // Get the main index
  const initialIndex = allSearchRequestQueryParams[0];
  // get active filters (only user defined filters)
  const facetFilters = getUserDefinedFacetFilters(initialIndex.facetFilters);
  const filterCategory: string[] = [];

  // For every active filter category create a query for a search request that
  // Has all the other active filter categories but itself
  // This is how returning all applicable filters per category works in consumer
  if (Array.isArray(facetFilters)) {
    for (let i = 0; i < facetFilters.length; i += 1) {
      const activeFilterSearchQuery = { ...initialIndex };

      // Remove one array from facetFilters in each duplication
      activeFilterSearchQuery.facetFilters = [...facetFilters.slice(0, i), ...facetFilters.slice(i + 1)];

      // Add the duplicated object to the search request array
      allSearchRequestQueryParamsCopy.push(activeFilterSearchQuery);

      // Store the sliced filter category name in an array
      const colonIndex = facetFilters.slice(i, i + 1)[0][0].indexOf(':');
      if (colonIndex !== -1) {
        const extractedString = facetFilters.slice(i, i + 1)[0][0].substring(0, colonIndex);
        filterCategory.push(extractedString);
      }
    }
  }
  return { filterCategory, allSearchRequestQueryParamsWithFacetHack: allSearchRequestQueryParamsCopy };
};

const getProviderFetchMoreUpdateQuery =
  (indexToLoadMoreOn: number) =>
  (prev: SearchQuery, { fetchMoreResult }: { fetchMoreResult: SearchQuery }) => {
    // if there's nothing more to fetch, just return prev
    if (!fetchMoreResult) return prev;

    const combinedResults: SearchQuery = cloneDeep(prev);
    const previousPageResults = prev?.SearchResult?.search?.[indexToLoadMoreOn]?.elements || [];
    const nextPageResults = fetchMoreResult?.SearchResult?.search?.[indexToLoadMoreOn]?.elements || [];

    // combine the results if existing results are not null or undefined
    if (
      combinedResults?.SearchResult?.search?.[indexToLoadMoreOn] !== undefined &&
      combinedResults?.SearchResult?.search?.[indexToLoadMoreOn] !== null
    ) {
      const combinedElements = {
        ...combinedResults.SearchResult.search[indexToLoadMoreOn],
        elements: [...previousPageResults, ...nextPageResults],
      };
      combinedResults.SearchResult.search[indexToLoadMoreOn] = combinedElements;
    }
    return combinedResults;
  };
export {
  updateUrl,
  filtersToKeyValuesMap,
  syncSearchStateToUrl,
  combineConfigWithUrlParam,
  addFiltersToFacetFilters,
  removeFiltersFromFacetFilters,
  clearFacetFilters,
  setFiltersForFacet,
  mapStateToQuery,
  combineResultsWithStates,
  combineFacetFilters,
  removeNegativeFilters,
  doFiltersNeedMerging,
  mergeFiltersAndStateWithResults,
  removeEmptyOrDuplicateFacetFilters,
  getQueryParamsFromSearchRequests,
  getFilterCategory,
  getProviderFetchMoreUpdateQuery,
};
