export const SEARCH_PROVIDER_DEFAULT_CONTEXT_VALUE = {
  getSearchResults: () => undefined,
  updateQuery: () => undefined,
  updateQueryForIndex: () => undefined,
  addFilters: () => undefined,
  setFiltersByFacet: () => undefined,
  removeFilters: () => undefined,
  clearFilters: () => undefined,
  setPage: () => undefined,
  setSortBy: () => undefined,
  applySearchStateUpdates: () => undefined,
  loadNextPage: () => undefined,
  isLoading: false,
};
