/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Grid, Typography2 } from '@coursera/cds-core';

const styles = {
  padding: css`
    padding: var(--cds-spacing-100);
  `,
  bold: css`
    font-weight: bold;
    margin: 0;
  `,
};
type ActiveSubscriptionsProps = {
  title: string;
  subtitle: string;
};

const ActiveSubscriptions = ({ title, subtitle }: ActiveSubscriptionsProps) => {
  return (
    <Grid item css={styles.padding}>
      <Typography2 variant="bodyPrimary" component="p">
        {title}
      </Typography2>
      <Typography2 variant="bodyPrimary" component="p" css={styles.bold}>
        {subtitle}
      </Typography2>
    </Grid>
  );
};

export default ActiveSubscriptions;
