import { map } from 'lodash';
import Q from 'q';

import logger from 'js/app/loggerSingleton';

import AdminGoogleTagManager from 'bundles/event-tracking/AdminGoogleTagManager';
import LearnerGoogleTagManager from 'bundles/event-tracking/LearnerGoogleTagManager';
import Eventing from 'bundles/event-tracking/eventing';
import { shouldLoadAdminGTM, shouldLoadLearnerGTM } from 'bundles/event-tracking/shouldLoadAdTracking';
import type { Tracker } from 'bundles/event-tracking/types';
import requestCountryCode from 'bundles/phoenix/template/models/requestCountryCode';

const isRestrictedPath =
  typeof window !== 'undefined' && ['/reset/confirm'].some((a) => window.location.pathname.includes(a));

const shouldLoadSoftTracking = requestCountryCode !== 'CN' && !isRestrictedPath;

type Event = keyof Tracker;

const getTrackers = (): Tracker[] => {
  if (shouldLoadLearnerGTM()) {
    // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
    return [new LearnerGoogleTagManager(), new Eventing()];
  } else if (shouldLoadAdminGTM()) {
    // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
    return [new AdminGoogleTagManager(), new Eventing()];
  } else if (shouldLoadSoftTracking) {
    // @ts-expect-error ts-migrate(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
    return [new Eventing()];
  } else {
    return [];
  }
};
const trackers = getTrackers();

const trackEvent = function <E extends Event>(event: E): Tracker[E] {
  // @ts-expect-error
  return function (...args: $TSFixMe[]) {
    const trackingCalls = map(trackers, function (tracker) {
      if (!tracker[event]) {
        logger.error(`${tracker.constructor.name} does not support tracking event ${event}`);
        return Q();
      }
      // @ts-expect-error
      return tracker[event](...args);
    });
    return Q.allSettled(trackingCalls);
  };
};

export { trackers };
export const trackSignup = trackEvent('trackSignup');
export const trackEnroll = trackEvent('trackEnroll');
export const trackDegreeEOICompletion = trackEvent('trackDegreeEOICompletion');
export const trackDegreeApplyNow = trackEvent('trackDegreeApplyNow');
export const trackScheduleACall = trackEvent('trackScheduleACall');
export const trackAction = trackEvent('trackAction');
export const trackS12NBulkPayment = trackEvent('trackS12NBulkPayment');
export const trackS12NSubscriptionPayment = trackEvent('trackS12NSubscriptionPayment');
export const trackS12NVCPayment = trackEvent('trackS12NVCPayment');
export const trackVCPayment = trackEvent('trackVCPayment');
export const trackGuidedProjectPayment = trackEvent('trackGuidedProjectPayment');
export const trackProjectPayment = trackEvent('trackProjectPayment');
export const trackEnterpriseContractPayment = trackEvent('trackEnterpriseContractPayment');
export const trackCourseraLiteSubscriptionPayment = trackEvent('trackCourseraLiteSubscriptionPayment');
export const trackCourseraPlusSubscriptionPayment = trackEvent('trackCourseraPlusSubscriptionPayment');
export const trackCourseraPlusPrepaidPayment = trackEvent('trackCourseraPlusPrepaidPayment');
export const trackMasterTrackPayment = trackEvent('trackMasterTrackPayment');
export const trackS12NPrepaidPayment = trackEvent('trackS12NPrepaidPayment');
export const trackEmailMeInfo = trackEvent('trackEmailMeInfo');
export const trackAddToCart = trackEvent('trackAddToCart');
export const trackEnrollButtonClick = trackEvent('trackEnrollButtonClick');
export const trackS12nEnrollModalButtonClick = trackEvent('trackS12nEnrollModalButtonClick');

const adsTracker = {
  trackers,
  trackSignup,
  trackEnroll,
  trackDegreeEOICompletion,
  trackDegreeApplyNow,
  trackScheduleACall,
  trackAction,
  trackS12NBulkPayment,
  trackS12NSubscriptionPayment,
  trackS12NVCPayment,
  trackVCPayment,
  trackGuidedProjectPayment,
  trackProjectPayment,
  trackEnterpriseContractPayment,
  trackCourseraLiteSubscriptionPayment,
  trackCourseraPlusSubscriptionPayment,
  trackCourseraPlusPrepaidPayment,
  trackMasterTrackPayment,
  trackS12NPrepaidPayment,
  trackEmailMeInfo,
  trackAddToCart,
  trackEnrollButtonClick,
  trackS12nEnrollModalButtonClick,
};

export default adsTracker;
