import tiktokLogoUrl from 'images/page-footer/tiktok.png';

/**
 * @todo should consider moving this to another shared package
 */
export const SOCIAL_MEDIA_ICON_URLS = {
  twitter: 'https://s3.amazonaws.com/coursera_assets/footer/twitter.png',
  facebook: 'https://s3.amazonaws.com/coursera_assets/footer/facebook.png',
  youtube: 'https://s3.amazonaws.com/coursera_assets/footer/youtube.png',
  instagram: 'https://s3.amazonaws.com/coursera_assets/footer/instagram.png',
  linkedin: 'https://s3.amazonaws.com/coursera_assets/footer/linkedin.png',
  tiktok: tiktokLogoUrl,
} as const;

export const MOBILE_BREAKPOINT_PX = 768;

export const MOBILE_BADGE_HEIGHT = 40;
export const MOBILE_BADGE_WIDTH = 135;

export const DESKTOP_BADGE_HEIGHT = 45;
export const DESKTOP_BADGE_WIDTH = 152;

export const US_BCORP_LOGO_WIDTH = 82;
export const FRENCH_CANADIAN_BCORP_LOGO_WIDTH = 112;

export const FOOTER_COLUMN_IDS = {
  collections: 'collections',
  degrees: 'degrees',
  courses: 'courses',
  certificates: 'certificates',
  coursera: 'coursera',
  community: 'community',
  more: 'more',
} as const;
