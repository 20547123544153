import * as React from 'react';
import { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import * as fullStory from 'js/lib/fullstory';

import DiscoveryCollectionsQuery from 'bundles/browse/components/DiscoveryCollectionsQuery.graphql';
import type {
  DiscoveryCollectionsQuery as DiscoveryCollectionsQueryData,
  DiscoveryCollectionsQueryVariables,
} from 'bundles/browse/components/__generated__/DiscoveryCollectionsQuery';
import GrowthPageExperiments from 'bundles/epic/clients/GrowthPage';
import SearchBarContentLegacy from 'bundles/search-common/components/searchbar/SearchBarContentLegacy';
import SearchBarContentWrapper from 'bundles/search-common/components/searchbar/SearchBarContentWrapper';
import type { SearchBarLocation } from 'bundles/search-common/constants';
import { getItemsListFromLocalStorageByKeyAndTrimOutdated } from 'bundles/search-common/utils/SearchUtils';
import {
  getShowNewAutocomplete,
  impressIntoNewAutocompleteExperiment,
} from 'bundles/search-common/utils/experimentUtils';

type Props = {
  hideSearch?: boolean;
  searchIsOpen?: boolean;
  isSearchPage?: boolean;
  isSEOEntityPage?: boolean;
  pageLocation?: SearchBarLocation;
  shouldFocusSearch?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  hideMobileSearchPage?: () => void;
  searchInputIsFocused?: boolean;
  enableOneStepSearch?: boolean;
  shouldShowExposedSearchAndReg?: boolean;
  smallSearchBar?: boolean;
  className?: string;
};

const SearchBar = ({
  isSEOEntityPage,
  isSearchPage,
  pageLocation,
  searchIsOpen,
  shouldFocusSearch,
  onBlur,
  onFocus,
  hideMobileSearchPage,
  enableOneStepSearch,
  shouldShowExposedSearchAndReg,
  searchInputIsFocused,
  className,
  smallSearchBar,
  hideSearch,
}: Props) => {
  const { data } = useQuery<DiscoveryCollectionsQueryData, DiscoveryCollectionsQueryVariables>(
    DiscoveryCollectionsQuery,
    {
      skip: getShowNewAutocomplete()
        ? getItemsListFromLocalStorageByKeyAndTrimOutdated('recently-viewed').length >= 3
        : getItemsListFromLocalStorageByKeyAndTrimOutdated('recently-viewed').length !== 0,
      variables: {
        contextType: 'PAGE',
        contextId: getShowNewAutocomplete() ? 'autocomplete-repeat-search-state' : 'search-zero-state',
      },
      context: { clientName: 'gatewayGql' },
      ssr: false,
    }
  );
  const collectionRecommendations = data?.DiscoveryCollections?.queryCollections?.[0];

  useEffect(() => {
    if (GrowthPageExperiments.get('fullStoryB2CEnabled') && !isSEOEntityPage) {
      fullStory.start();
    }
  }, [isSEOEntityPage]);

  if (getShowNewAutocomplete()) {
    if (hideSearch) return null;
    return (
      <SearchBarContentWrapper
        collectionRecommendations={collectionRecommendations}
        searchIsOpen={searchIsOpen}
        hideMobileSearchPage={hideMobileSearchPage}
      />
    );
  }

  return (
    <SearchBarContentLegacy
      collectionRecommendations={collectionRecommendations}
      onBlur={onBlur}
      onFocus={() => {
        impressIntoNewAutocompleteExperiment();
        onFocus?.();
      }}
      searchIsOpen={searchIsOpen}
      hideMobileSearchPage={hideMobileSearchPage}
      enableOneStepSearch={enableOneStepSearch}
      shouldShowExposedSearchAndReg={shouldShowExposedSearchAndReg}
      searchInputIsFocused={searchInputIsFocused}
      isSearchPage={isSearchPage}
      pageLocation={pageLocation}
      shouldFocusSearch={shouldFocusSearch}
      className={className}
      smallSearchBar={smallSearchBar}
      hideSearch={hideSearch}
    />
  );
};

export default SearchBar;
