import type {
  DescriptionPage_CourseType as CourseType,
  DescriptionPage_DifficultyLevel as DifficultyLevel,
  DescriptionPage_ProductVariant as ProductVariant,
} from '__generated__/graphql-types';
import type Maybe from 'graphql/tsutils/Maybe';
import { capitalize, orderBy } from 'lodash';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import * as user from 'js/lib/user';

import localStorageEx from 'bundles/common/utils/localStorageEx';
import {
  ALL_PRODUCTS_INDEX_TYPE,
  NUMBER_OF_RESULTS_PER_PAGE,
  RECENTLY_VIEWED_LOCAL_STORAGE_NAME,
  RECENT_ITEM_NUMBER_OF_DAYS_TO_LAST,
  RECENT_SEARCHES_LOCAL_STORAGE_NAME,
  SEARCH_STORE_OVERFLOW,
  SEARCH_UPSELL_TYPENAME,
  SHORT_FORM_CONTENT_ENTITY_TYPES,
  UCI_APM_IMAGE_URL,
  UCI_APM_OBJECT_ID,
} from 'bundles/search-common/constants';
import { SEARCH_FILTER_OPTION_ORDER_OVERRIDES_REIMAGINE_VARIANT_C } from 'bundles/search-common/constants/filter';
import type {
  ProductCardCourse,
  ProductCardSpecialization,
  SearchHit,
  SearchHitsOverflow,
  SearchProductHit,
  SearchUpsellHit,
} from 'bundles/search-common/providers/searchTypes';
import type { Hits, RecentlyViewed, Viewed } from 'bundles/search-common/types/autocomplete';
import { getAllTabIndexName, getIndexTypeToNameMap } from 'bundles/search-common/utils/utils';
import inServerContext from 'bundles/ssr/util/inServerContext';

import _t from 'i18n!nls/search-common';

export { getAllTabIndexName } from 'bundles/search-common/utils/utils';

export const isShortFormContent = (entityType?: string) => SHORT_FORM_CONTENT_ENTITY_TYPES.includes(entityType || '');

export const getNumberOfSearchResults = (currentTab: string, allSearchResults: Record<string, { nbHits: number }>) => {
  const indexTypeToNameMap = getIndexTypeToNameMap();
  const indexName = currentTab === ALL_PRODUCTS_INDEX_TYPE ? getAllTabIndexName() : indexTypeToNameMap[currentTab];
  return allSearchResults?.[indexName]?.nbHits;
};

// Creates video item id from parentLessonObjectUrl since the videoItemId currently isn't being passed in algolia
// for parent lessons. This will be able to be removed after it is added to the algolia index after short form
// content launch
export const getLessonsVideoObjectUrl = (parentCourseId?: string, parentLessonObjectUrl?: string) => {
  return tupleToStringKey([
    'sfcvideo',
    parentCourseId || '',
    parentLessonObjectUrl?.split('/')[3]?.split('?')[0] || '',
  ]);
};

// TODO(htran) remove after UCI APM Contentful migration is done in GNG-1259
export const getSearchCardImageUrl = (hit?: {
  objectID?: Maybe<string>;
  imageUrl?: Maybe<string>;
  partnerLogos?: Maybe<Array<string>>;
}): string => {
  if (!hit) {
    return '';
  } else if (hit.objectID && hit.objectID === UCI_APM_OBJECT_ID) {
    return UCI_APM_IMAGE_URL;
  } else {
    const productImage = hit?.imageUrl || '';

    // If image is a gif it is detrimental to performance, serve the partner logo instead.
    if (/\.gif/.test(productImage)) {
      return hit?.partnerLogos ? hit.partnerLogos[0] : productImage;
    } else {
      return productImage;
    }
  }
};

export function getItemsListFromLocalStorageByKeyAndTrimOutdated(
  keyName: typeof RECENT_SEARCHES_LOCAL_STORAGE_NAME
): string[];

export function getItemsListFromLocalStorageByKeyAndTrimOutdated(
  keyName: typeof RECENTLY_VIEWED_LOCAL_STORAGE_NAME
): Viewed[];

export function getItemsListFromLocalStorageByKeyAndTrimOutdated(keyName: string): string[] | Viewed[] {
  if (!inServerContext && localStorageEx.isAvailable()) {
    const localStorageItems: RecentlyViewed[] = localStorageEx.getItem(keyName, JSON.parse, []);
    const now = new Date();
    const filteredLocalStorageItems = localStorageItems.filter((item: RecentlyViewed) => {
      const dateSaved = new Date(item.dateSaved);
      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      return (
        dateSaved && (now.getTime() - dateSaved.getTime()) / millisecondsPerDay < RECENT_ITEM_NUMBER_OF_DAYS_TO_LAST
      );
    });

    localStorageEx.setItem(keyName, filteredLocalStorageItems, JSON.stringify);
    return filteredLocalStorageItems.map((item: RecentlyViewed) => item.suggestion);
  } else {
    return [];
  }
}

// productType from XDP comes from multiple different sources which is why there are multiple cases
export const getProductType = (productType: CourseType | ProductVariant | string | null): string => {
  if (productType) {
    switch (productType) {
      case 'STANDARD_COURSE':
      case 'COURSE':
        return 'Course';
      case 'PROJECT':
        return 'Project';
      case 'RHYME_PROJECT':
      case 'GUIDED_PROJECT':
        return 'Guided Project';
      case 'SPECIALIZATION':
      case 'STANDARD_SPECIALIZATION':
        return 'Specialization';
      case 'PROFESSIONAL_CERTIFICATE':
      case 'GOOGLE_CERTIFICATE':
        return 'Professional Certificate';
      case 'MASTERTRACK':
      case 'MASTER_TRACK':
        return 'Mastertrack';
      case 'PostgraduateDiploma':
      case 'POSTGRADUATE_DIPLOMA':
        return 'Postgraduate Diploma';
      case 'GRADUATE_CERTIFICATE':
        return 'Graduate Certificate';
      case 'UNIVERSITY_CERTIFICATE':
        return 'University Certificate';
      case 'BACHELORS_DEGREE':
      case 'BachelorsDegree':
      case 'MASTERS_DEGREE':
      case 'DEGREE':
        return 'Degree';
      case 'LESSON':
        return 'Lesson';
      case 'VIDEO':
        return 'Video';
      case 'SKILL_CAREERROLE':
        return 'Career Role';
      default:
        return '';
    }
  }
  return '';
};
export const saveRecentlyViewed = ({
  id,
  name,
  slug,
  path,
  imageIconWithSize,
  partnerName,
  partnerLogo,
  difficulty,
  productType,
  isPathwayProduct,
}: {
  id?: string;
  name: string;
  slug?: string;
  path: string;
  imageIconWithSize: {
    imageUrl: string;
    size: number;
  };
  partnerName?: string;
  partnerLogo?: string;
  difficulty?: DifficultyLevel | null;
  productType?: CourseType | ProductVariant | string | null;
  isPathwayProduct?: boolean;
}) => {
  if (!inServerContext && localStorageEx.isAvailable()) {
    const recentlyViewed = getItemsListFromLocalStorageByKeyAndTrimOutdated(RECENTLY_VIEWED_LOCAL_STORAGE_NAME);
    const elementIndex = recentlyViewed.findIndex((storedItem: Viewed) => storedItem.name === name);
    if (elementIndex !== -1) {
      recentlyViewed.splice(elementIndex, 1);
    }
    recentlyViewed.unshift({
      id,
      name,
      slug,
      path,
      image: imageIconWithSize,
      partnerName: partnerName || null,
      partnerLogo,
      difficulty: difficulty ? capitalize(difficulty) : '',
      productType: productType ? getProductType(productType) : '',
      isPathwayProduct: isPathwayProduct || false,
    });
    const now = new Date();
    const recentlyViewedWithTimestamp = recentlyViewed.map((suggestion: Viewed) => ({ suggestion, dateSaved: now }));
    localStorageEx.setItem(RECENTLY_VIEWED_LOCAL_STORAGE_NAME, recentlyViewedWithTimestamp, JSON.stringify);
  }
};
export const saveRecentlySearched = (searchTerm: string) => {
  if (!inServerContext && localStorageEx.isAvailable() && searchTerm.trim() !== '') {
    const recentlySearched = getItemsListFromLocalStorageByKeyAndTrimOutdated(RECENT_SEARCHES_LOCAL_STORAGE_NAME);
    const elementIndex = recentlySearched.findIndex((storedItem: string) => storedItem === searchTerm);
    if (elementIndex !== -1) {
      recentlySearched.splice(elementIndex, 1);
    }
    recentlySearched.unshift(searchTerm);
    const now = new Date();
    const recentlySearchedWithTimestamp = recentlySearched.map((suggestion: string) => ({
      suggestion,
      dateSaved: now,
    }));
    localStorageEx.setItem(RECENT_SEARCHES_LOCAL_STORAGE_NAME, recentlySearchedWithTimestamp, JSON.stringify);
  }
};

export const getDataToTrackFromHit = (hit: Hits) => {
  const { indexPosition, hitPosition, indexName, objectID } = hit;
  return { indexPosition, hitPosition, indexName, objectID };
};

export const getNameFromDomainOrSubdomainId = (id: string) => {
  const name = id
    .split('-')
    .map((word: string) => word[0].toUpperCase() + word.substring(1))
    .join(' ');
  return name;
};

export type ItemType = {
  count: number;
  isRefined: boolean;
  label: string;
  value: string | Array<string>;
  supportText?: string;
};

type ItemBase = {
  count: number;
  isRefined: boolean;
  label: string;
};

export const getFilterItemsOrder = <Item extends ItemBase>(items: Item[], attribute: string) => {
  // use specific ordering for Level and Duration only
  const optionItemOrderOverride = SEARCH_FILTER_OPTION_ORDER_OVERRIDES_REIMAGINE_VARIANT_C[attribute];
  let sortedItems: Item[] = items;

  if (!optionItemOrderOverride) {
    sortedItems = orderBy(items, ['isRefined', 'count', 'label'], ['desc', 'desc', 'asc']);
  } else {
    sortedItems.sort((item1, item2) => {
      if (item1.isRefined && !item2.isRefined) {
        return -1;
      } else if (!item1.isRefined && item2.isRefined) {
        return 1;
      } else if (optionItemOrderOverride.indexOf(item1.label) < optionItemOrderOverride.indexOf(item2.label)) {
        return -1;
      } else {
        return 1;
      }
    });
  }
  return sortedItems;
};

export const getPartners = (id: string, cobrandingEnabled: boolean, partners?: string[]) => {
  if (!partners || !partners.length) return '';

  if (partners.length > 1 && cobrandingEnabled) {
    return `${partners[0]}, ${partners[1]}`;
  }

  return partners[0];
};
export const getIsPathwayContent = (hit: SearchProductHit): boolean => {
  if (hit?.productCard?.productTypeAttributes) {
    const productTypeAttributes = hit.productCard.productTypeAttributes;
    if ('isPathwayContent' in productTypeAttributes) {
      return (
        (productTypeAttributes as Partial<ProductCardCourse> | Partial<ProductCardSpecialization>).isPathwayContent ??
        false
      );
    }
  }
  return false;
};

export const getAvgRating = (hit: SearchProductHit): number | undefined => {
  if (hit?.productCard?.productTypeAttributes) {
    const productTypeAttributes = hit.productCard.productTypeAttributes;
    if ('rating' in productTypeAttributes) {
      return productTypeAttributes.rating ?? undefined;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};
export const getNumOfRatings = (hit: SearchProductHit): number | undefined => {
  if (hit?.productCard?.productTypeAttributes) {
    const productTypeAttributes = hit.productCard.productTypeAttributes;
    if ('reviewCount' in productTypeAttributes) {
      return productTypeAttributes.reviewCount ?? undefined;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getUpsellCardPosition = (isMobile: boolean, showUpsellCard: boolean | null | undefined) => {
  return showUpsellCard ? (isMobile ? 3 : user.isAuthenticatedUser() ? 5 : 3) : -1;
};

export const getSearchUpsellHit = (): SearchUpsellHit => {
  const title = _t('Find your dream career by exploring them all.');

  return { id: 'coursera_plus_upsell_card', title, breakpoint: 'xs', __typename: SEARCH_UPSELL_TYPENAME };
};

export const handleOverflowHits = (
  hits: Array<SearchHit | SearchUpsellHit>,
  page: number,
  isPaginatedLastPage: boolean,
  searchHitsOverflow: SearchHitsOverflow
): Array<SearchHit | SearchUpsellHit> => {
  if (hits.length > NUMBER_OF_RESULTS_PER_PAGE && !isPaginatedLastPage && localStorageEx.isAvailable()) {
    const newHits = hits.slice(0, -1);
    const extraHit = hits[hits.length - 1];
    if (extraHit && !searchHitsOverflow.extraHits[page + 1]) {
      searchHitsOverflow.extraHits[page + 1] = extraHit;
      localStorageEx.setItem(SEARCH_STORE_OVERFLOW, searchHitsOverflow, JSON.stringify);
    }
    return newHits;
  }

  return hits;
};

export const handleFirstPageHits = (
  baseHits: Array<SearchHit | SearchUpsellHit>,
  upsellHit: SearchUpsellHit,
  upsellCardIndex: number,
  page: number,
  isPaginatedLastPage: boolean,
  searchHitsOverflow: SearchHitsOverflow
): Array<SearchHit | SearchUpsellHit> => {
  if (upsellCardIndex > baseHits.length) {
    return [...baseHits, upsellHit];
  }

  if (baseHits.length < NUMBER_OF_RESULTS_PER_PAGE && upsellCardIndex < baseHits.length) {
    return [...baseHits.slice(0, upsellCardIndex), upsellHit, ...baseHits.slice(upsellCardIndex)];
  }

  if (baseHits.length === NUMBER_OF_RESULTS_PER_PAGE) {
    const hitsWithUpsell = [...baseHits.slice(0, upsellCardIndex), upsellHit, ...baseHits.slice(upsellCardIndex)];
    return handleOverflowHits(hitsWithUpsell, page, isPaginatedLastPage, searchHitsOverflow);
  }

  return baseHits;
};

export const handleOtherPagesHits = (
  baseHits: Array<SearchHit | SearchUpsellHit>,
  page: number,
  isPaginatedLastPage: boolean,
  searchHitsOverflow: SearchHitsOverflow
): Array<SearchHit | SearchUpsellHit> => {
  const extraHit = searchHitsOverflow.extraHits[page] || null;
  const resultHits = extraHit ? [extraHit, ...baseHits] : baseHits;
  return handleOverflowHits(resultHits, page, isPaginatedLastPage, searchHitsOverflow);
};

export const getCombinedHits = ({
  hits,
  page,
  totalHits,
  showUpsellCard,
  upsellCardIndex,
  searchParams,
  addOverFlowCard,
}: {
  hits: Array<SearchHit> | undefined;
  page: number;
  totalHits: number | null | undefined;
  showUpsellCard: boolean;
  upsellCardIndex: number;
  searchParams: Record<string, string>;
  addOverFlowCard: boolean;
}): Array<SearchHit | SearchUpsellHit> => {
  const pageHits: Array<SearchHit | SearchUpsellHit> = hits || [];
  const isPaginatedLastPage = page === Math.ceil((totalHits ?? 0) / NUMBER_OF_RESULTS_PER_PAGE);
  const addUpsellHitChanges = showUpsellCard || addOverFlowCard;

  if (addUpsellHitChanges && (pageHits?.length ?? 0) > 0) {
    let searchHitsOverflow: SearchHitsOverflow = { params: {}, extraHits: {}, initialTimestamp: '' };
    if (localStorageEx.isAvailable()) {
      const storedOverflow = localStorageEx.getItem(SEARCH_STORE_OVERFLOW, JSON.parse, null);
      const now = new Date();
      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      const isOlderThan24Hours =
        !storedOverflow?.initialTimestamp ||
        now.getTime() - new Date(storedOverflow.initialTimestamp).getTime() > millisecondsPerDay;

      searchHitsOverflow = isOlderThan24Hours
        ? { params: {}, extraHits: {}, initialTimestamp: new Date().toISOString() }
        : storedOverflow;

      const paramsMatch = JSON.stringify(searchHitsOverflow.params) === JSON.stringify(searchParams);
      if (!paramsMatch) {
        searchHitsOverflow = { params: searchParams, extraHits: {}, initialTimestamp: new Date().toISOString() };
        localStorageEx.setItem(SEARCH_STORE_OVERFLOW, searchHitsOverflow, JSON.stringify);
      }
    }
    const baseHits: Array<SearchHit | SearchUpsellHit> = [...(hits || [])];
    const upsellHit = getSearchUpsellHit();
    const resultHits =
      page === 1
        ? handleFirstPageHits(baseHits, upsellHit, upsellCardIndex, page, isPaginatedLastPage, searchHitsOverflow)
        : handleOtherPagesHits(baseHits, page, isPaginatedLastPage, searchHitsOverflow);

    return resultHits;
  }
  return pageHits;
};
