// CourseToolBarPluginProvider.tsx
import * as React from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';
import type { ReactNode } from 'react';

import type {
  CourseToolBarPluginContextType,
  Plugin,
} from 'bundles/course-v2/components/navigation/CourseToolBar/CourseToolBarTypes';
import { courseToolBarLocalStorageHandler } from 'bundles/course-v2/components/navigation/CourseToolBar/courseToolBarLocalStorageHandler';

export const CourseToolBarPluginContext = createContext<CourseToolBarPluginContextType | undefined>(undefined);

export const CourseToolBarPluginProvider = ({ children }: { children: ReactNode }) => {
  const toolBarStateProvider = courseToolBarLocalStorageHandler();
  const toolBarState = toolBarStateProvider.getState();
  const [plugins, setPlugins] = useState<Plugin[]>([]);
  const [expanded, setExpanded] = useState(toolBarState?.expanded ?? false);
  const [toolSelected, setToolSelected] = useState<string | null>('coach');

  const closeTools = useCallback<() => $TSFixMe>(() => {
    setExpanded(false);
  }, []);

  const _setPlugins = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (newPlugins: Plugin[]) => {
      setPlugins(newPlugins);
    },
    [setPlugins]
  );

  const _setExpanded = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (newExpanded: boolean) => {
      setExpanded(newExpanded);
    },
    [setExpanded]
  );

  const _setToolSelected = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (tool: string | null) => {
      setToolSelected(tool);
    },
    [setToolSelected]
  );

  React.useEffect(() => {
    toolBarStateProvider.setState({ expanded, toolSelected });
  }, [toolBarStateProvider, expanded, toolSelected]);

  const contextValue = useMemo(
    () => ({
      plugins,
      setPlugins: _setPlugins,
      expanded,
      setExpanded: _setExpanded,
      toolSelected,
      setToolSelected: _setToolSelected,
      closeTools,
    }),
    [plugins, _setPlugins, expanded, _setExpanded, toolSelected, _setToolSelected, closeTools]
  );

  return <CourseToolBarPluginContext.Provider value={contextValue}>{children}</CourseToolBarPluginContext.Provider>;
};
