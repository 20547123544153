import * as React from 'react';

import { useQuery } from '@apollo/client';

import { useParams } from 'js/lib/useRouter';

import { ROUTE_ARGUMENTS } from 'bundles/admin-shared/analytics/constants';
import { AdminRouteNames, getAdminV2Routes } from 'bundles/admin-v2/constants/routes';
import AdminRightNavLink from 'bundles/admin/components/AdminRightNavLink';
import { GROUP } from 'bundles/admin/constants/AdminRouteNames';
import type AdminPermission from 'bundles/admin/utils/AdminPermission';
import GetSelectedPartnerSlugQuery from 'bundles/page-header/components/queries/GetSelectedPartnerSlugQuery.graphql';
import type {
  GetSelectedPartnerSlugQuery as GetSelectedPartnerSlugQueryData,
  GetSelectedPartnerSlugQueryVariables,
} from 'bundles/page-header/components/queries/__generated__/GetSelectedPartnerSlugQuery';

type AdminNavItem = {
  isHidden: boolean;
  href?: string;
  name: string;
  target: string;
  openInNewWindow?: boolean;
};

type Props = {
  showAdminLinks?: boolean;
  adminPermission?: AdminPermission;
  handleGroupsClick: () => void;
};

const AdminRightNavButtons: React.FC<Props> = ({ showAdminLinks, adminPermission, handleGroupsClick }) => {
  const routeShortName: string | undefined = useParams()[ROUTE_ARGUMENTS.INSTITUTION_SLUG];
  const { data } = useQuery<GetSelectedPartnerSlugQueryData, GetSelectedPartnerSlugQueryVariables>(
    GetSelectedPartnerSlugQuery,
    {
      context: { clientName: 'gatewayGql' },
      skip: !showAdminLinks || !!routeShortName,
    }
  );
  if (showAdminLinks) {
    const userPref = data?.UserPreference?.me;
    const partner =
      userPref?.__typename === 'UserPreference_AdminSelectedPartnerUserPreference' ? userPref.partner : undefined;
    const adminPageList: Array<AdminNavItem> = getAdminV2Routes(
      routeShortName || partner?.slug,
      adminPermission as AdminPermission
    );

    return (
      <>
        {adminPageList.map((item: AdminNavItem) => {
          if (!item.isHidden) {
            const isActive = item.href && location.pathname.includes(item.href);
            const currentPageLink = isActive ? 'current-page' : '';
            const adminRightNavProps = {
              label: item.name,
              name: item.name,
              wrapperClassName: `${currentPageLink}`,
              href: item.href,
              target: item.target,
              pathname: location.pathname,
              onClick:
                item.target === AdminRouteNames.MY_GROUP || item.target === GROUP ? handleGroupsClick : undefined,
              openInNewWindow: item.openInNewWindow,
            };

            return <AdminRightNavLink {...adminRightNavProps} key={item.name} />;
          } else {
            return null;
          }
        })}
      </>
    );
  } else {
    return null;
  }
};

export default AdminRightNavButtons;
