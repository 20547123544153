/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import Imgix from 'js/components/Imgix';

import { Typography2 } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import { logo } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/megamenu';

const styles = {
  explicitLink: css`
    color: var(--cds-color-neutral-primary);

    /* !important required to override global styles */
    text-decoration: none !important;

    & .explicit-link-text {
      text-decoration: underline;
    }

    &:hover > .explicit-link-text {
      text-decoration: none;
    }
  `,
  courseraText: css`
    color: var(--cds-color-interactive-primary);
    margin-left: var(--cds-spacing-50);
  `,
  logoContainer: css`
    display: inline-flex;
  `,
  plusLogo: css`
    margin-left: 2px;
  `,
};

export const CourseraPlusLink = () => {
  const track = useTracker();

  return (
    <TrackedA
      css={styles.explicitLink}
      href="/courseraplus"
      onClick={() => {
        track('click_megamenu_item', {
          megamenuItemLink: '/courses?query=free',
          megamenuItemName: 'coursera-plus',
          megamenuSection: 'footer',
          megamenuItemType: 'other',
        });
      }}
    >
      {/* Hard-coded className used here to increase CSS specificity */}
      <span className="explicit-link-text">{_t('Learn more about')}</span>
      <Typography2 variant="actionPrimary" component="span" css={styles.courseraText}>
        {_t('Coursera')}
      </Typography2>
      <div css={styles.logoContainer}>
        <Imgix src={logo.PLUS_PILL_BLUE} alt={_t('Coursera Plus')} maxWidth={32} maxHeight={12} css={styles.plusLogo} />
      </div>
    </TrackedA>
  );
};
