import user from 'js/lib/user';

import { isMonthly, isYearly } from 'bundles/coursera-plus/constants/CourseraPlusProductVariant';
import { useSubscriptionsByUser } from 'bundles/coursera-plus/utils/withSubscriptionsByUser';

export enum CourseraPlusSubscriptionType {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export type NewCPlusEntryPointsEligibilityStatus = {
  shouldShowCourseraPlusBanner: boolean;
  courseraPlusSubscriptionCycle?: CourseraPlusSubscriptionType;
};

const useNewCPlusEntryPointsEligibilityCheck = (): NewCPlusEntryPointsEligibilityStatus => {
  const { subscriptions } = useSubscriptionsByUser();

  const hasActiveCourseraPlusOrLiteSubscription = subscriptions?.find(
    (subscription) =>
      subscription.isActive && (subscription.isCourseraPlusSubscription || subscription.isCourseraLiteSubscription)
  );

  const isEligibleUser = user.isAuthenticatedUser() && !hasActiveCourseraPlusOrLiteSubscription;

  let courseraPlusSubscriptionCycle = undefined;
  if (hasActiveCourseraPlusOrLiteSubscription?.productSku) {
    if (isMonthly(hasActiveCourseraPlusOrLiteSubscription.productSku)) {
      courseraPlusSubscriptionCycle = CourseraPlusSubscriptionType.MONTHLY;
    } else if (isYearly(hasActiveCourseraPlusOrLiteSubscription.productSku)) {
      courseraPlusSubscriptionCycle = CourseraPlusSubscriptionType.YEARLY;
    }
  }

  return {
    shouldShowCourseraPlusBanner: isEligibleUser,
    courseraPlusSubscriptionCycle,
  };
};

export default useNewCPlusEntryPointsEligibilityCheck;
