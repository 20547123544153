/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import Imgix from 'js/components/Imgix';

import { Grid, Typography2 } from '@coursera/cds-core';
import { ShareIcon } from '@coursera/cds-icons';
import type { SectionName } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import { getLandingPageLink, logo, numberOfProductsWithPlusSign } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import GrowthDiscoveryExperiments from 'bundles/epic/clients/GrowthDiscovery';

import _t from 'i18n!nls/coursera-plus';

type Props = {
  tabIndex?: number;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLElement>) => void;
  targetRef?: (x0: HTMLAnchorElement | null) => void;
  role?: string;
  pageSectionName?: SectionName;
};

const styles = {
  linkWrapper: css`
    a {
      display: inline-block;
      width: 100%;

      /* override the styles for <a> tag from HeaderRightNavV2.styl */
      padding: 0 !important;
      text-decoration: none;

      &:hover,
      &:focus {
        /* Inherit the current dropdown item hover color */
        background-color: #f0f0f0 !important;
        outline: none !important;
      }
    }
  `,
  container: css`
    width: 100%;
    padding: var(--cds-spacing-100) var(--cds-spacing-200);
    margin: 0;
  `,
  plusLogo: css`
    margin-left: 2px;
  `,
  buttonText: css`
    color: var(--cds-color-interactive-primary);
  `,
};

const CourseraPlusButton = ({
  targetRef,
  tabIndex,
  onKeyDown,
  role,
  pageSectionName = 'page_header_popup_menu',
}: Props) => {
  const track = useTracker();

  return (
    <li css={styles.linkWrapper}>
      <a
        href={getLandingPageLink()}
        onClick={() => {
          track('click_button', {
            button: { name: 'c_plus_learn_more' },
            pageSection: { sectionName: pageSectionName },
          });
        }}
        tabIndex={tabIndex}
        onKeyDown={onKeyDown}
        role={role}
        ref={(containerRef: HTMLAnchorElement) => {
          if (targetRef) {
            targetRef(containerRef);
          }
        }}
      >
        <Grid container direction="column" alignItems="center" css={styles.container}>
          <Grid item container direction="column">
            <Grid item container justifyContent="space-between" alignItems="center" wrap="nowrap">
              <Grid item container alignItems="center">
                <Grid item>
                  <Typography2 variant="actionPrimary" component="p" css={styles.buttonText}>
                    {_t('Get Coursera')}
                  </Typography2>
                </Grid>
                <Grid item css={{ display: 'inline-flex' }}>
                  <Imgix
                    src={logo.PLUS_PILL_BLUE}
                    alt={_t('Coursera Plus')}
                    maxWidth={32}
                    maxHeight={12}
                    css={styles.plusLogo}
                  />
                </Grid>
              </Grid>
              {GrowthDiscoveryExperiments.preview('simplifiedMegamenuQ12025') === 'control' && (
                <Grid item css={{ display: 'flex' }}>
                  <ShareIcon size="small" color="interactive" />
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography2 variant="bodySecondary" component="p">
                {_t('Access #{numberOfCourses} courses', { numberOfCourses: numberOfProductsWithPlusSign })}
              </Typography2>
            </Grid>
          </Grid>
        </Grid>
      </a>
    </li>
  );
};

export default CourseraPlusButton;
