import { graphql } from 'react-apollo';

import { useQuery } from '@apollo/client';
import { CourseraTierSubscriptions_ProductType as ProductType } from '__generated__/graphql-types';
import type {
  CourseraTierSubscriptions_ProductOwnership as ProductOwnership,
  CourseraTierSubscriptionsFetchQueriesQueryProductOwnershipsByUserArgs as ProductOwnershipsByUserQueryVariables,
  CourseraTierSubscriptions_UserProductOwnershipId as UserProductOwnershipId,
} from '__generated__/graphql-types-do-not-use';
import gql from 'graphql-tag';
import { branch } from 'recompose';

import user from 'js/lib/user';
import waitForGraphQL from 'js/lib/waitForGraphQL';

// I had to alias the id field on this query to prevent a bug.
// Graphql expects the id field to be unique and uses it to
// facilitate caching, but it appears that setting its value
// to an object can have some unintended side effects. In this case
// all objects returned were always same as the first in the array.
// Setting fetchPolicy: 'no-cache' didn't help here.
// Related: https://graphql.org/learn/global-object-identification/
export const getProductOwnershipsByUserQuery = gql`
  query getProductOwnershipsByUser($userId: ID!) {
    CourseraTierSubscriptionsQueries {
      queryProductOwnershipsByUser(userId: $userId) {
        userProductOwnershipId: id {
          userId
          productType
          productItemId
        }
        owns
        expiredOwns
        pendingConfirmation
        lastUpdatedTime
        expiresAt
        firstOwnedAt
      }
    }
  }
`;

// Replacing id from generated types with the new
// userProductOwnershipId property we aliased in the FE query
export type ProductOwnershipsProps = Omit<ProductOwnership, 'id'> & {
  userProductOwnershipId: UserProductOwnershipId;
};

type ProductOwnershipsByUserQueryData = {
  CourseraTierSubscriptionsQueries: {
    queryProductOwnershipsByUser?: ProductOwnershipsProps[] | null;
  };
};

export type PropsFromCourseraPlusProductOwnerships = {
  courseraPlusProductOwnership?: ProductOwnershipsProps;
  courseraLiteProductOwnership?: ProductOwnershipsProps;
  ownsCourseraPlus?: boolean;
  ownsCourseraLite?: boolean;
  hasUpgradedToCourseraPlus?: boolean;
};

export const getOwnsCourseraLiteFromProductOwnership = (productOwnership: ProductOwnershipsProps) => {
  const productType = productOwnership?.userProductOwnershipId?.productType;
  if (!productType) {
    return false;
  }
  return (
    productOwnership?.owns && productOwnership?.userProductOwnershipId?.productType === ProductType.CourseraTierLite
  );
};

export const getOwnsCourseraPlusFromProductOwnership = (productOwnership: ProductOwnershipsProps) => {
  const productType = productOwnership?.userProductOwnershipId?.productType;
  if (!productType) {
    return false;
  }
  return (
    productOwnership?.owns &&
    [ProductType.CourseraPlus, ProductType.CourseraPlusSubscription].some((currentType) => productType === currentType)
  );
};

const withCourseraPlusProductOwnerships = <InputProps extends {} | ProductOwnershipsByUserQueryVariables>(
  isNotRequired?: boolean
) => {
  const graphqlHoc = !isNotRequired ? waitForGraphQL : graphql;

  return branch<PropsFromCourseraPlusProductOwnerships & InputProps>(
    () => user.isAuthenticatedUser(),
    graphqlHoc<
      InputProps,
      ProductOwnershipsByUserQueryData,
      ProductOwnershipsByUserQueryVariables,
      PropsFromCourseraPlusProductOwnerships
    >(getProductOwnershipsByUserQuery, {
      options: () => ({
        variables: { userId: user.get().id?.toString() },
        context: { clientName: 'gatewayGql' },
      }),
      props: ({ data, ownProps }) => {
        const productOwnershipsByUser = data?.CourseraTierSubscriptionsQueries?.queryProductOwnershipsByUser;
        const courseraPlusProductOwnership = productOwnershipsByUser?.find(getOwnsCourseraPlusFromProductOwnership);
        const courseraLiteProductOwnership = productOwnershipsByUser?.find(getOwnsCourseraLiteFromProductOwnership);
        const ownsCourseraPlus = !!courseraPlusProductOwnership;
        const ownsCourseraLite = !!courseraLiteProductOwnership;
        const previouslyOwnedCourseraLite = productOwnershipsByUser?.some(
          (productOwnership) =>
            !productOwnership?.owns &&
            productOwnership?.userProductOwnershipId?.productType === ProductType.CourseraTierLite &&
            productOwnership.expiredOwns
        );

        return {
          ...ownProps,
          courseraPlusProductOwnership,
          courseraLiteProductOwnership,
          ownsCourseraPlus,
          ownsCourseraLite,
          hasUpgradedToCourseraPlus: previouslyOwnedCourseraLite && ownsCourseraPlus,
        };
      },
    })
  );
};

export const useCourseraPlusProductOwnerships = (): PropsFromCourseraPlusProductOwnerships => {
  const { data } = useQuery<ProductOwnershipsByUserQueryData, ProductOwnershipsByUserQueryVariables>(
    getProductOwnershipsByUserQuery,
    {
      variables: { userId: user.get().id?.toString() || '' },
      context: { clientName: 'gatewayGql' },
      skip: !user.isAuthenticatedUser(),
    }
  );

  const productOwnershipsByUser = data?.CourseraTierSubscriptionsQueries?.queryProductOwnershipsByUser;
  const courseraPlusProductOwnership = productOwnershipsByUser?.find(getOwnsCourseraPlusFromProductOwnership);
  const courseraLiteProductOwnership = productOwnershipsByUser?.find(getOwnsCourseraLiteFromProductOwnership);
  const ownsCourseraPlus = !!courseraPlusProductOwnership;
  const ownsCourseraLite = !!courseraLiteProductOwnership;
  const previouslyOwnedCourseraLite = productOwnershipsByUser?.some(
    (productOwnership) =>
      !productOwnership?.owns &&
      productOwnership?.userProductOwnershipId?.productType === ProductType.CourseraTierLite &&
      productOwnership.expiredOwns
  );

  return {
    courseraPlusProductOwnership,
    courseraLiteProductOwnership,
    ownsCourseraPlus,
    ownsCourseraLite,
    hasUpgradedToCourseraPlus: previouslyOwnedCourseraLite && ownsCourseraPlus,
  };
};

export default withCourseraPlusProductOwnerships;
