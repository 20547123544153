import * as React from 'react';

import classNames from 'classnames';

import initBem from 'js/lib/bem';

import { SOCIAL_MEDIA_ICON_URLS } from 'bundles/page-footer/components/global-footer/constants';
import getFooterLinks from 'bundles/page-footer/components/global-footer/data/footerLinks';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import LazyImgix from 'bundles/page/components/shared/LazyImgix';

import _t from 'i18n!nls/page';

import 'css!bundles/page-footer/components/seo-sub-footer/__styles__/SocialMediaLinksV2';

type Props = {
  enableNewDefensiveSubfooter?: boolean;
};

const getSocialMediaLinkItems = () => {
  const links = getFooterLinks();

  return [
    {
      src: SOCIAL_MEDIA_ICON_URLS.facebook,
      tracking: 'facebook_link',
      alt: _t('Coursera Facebook'),
      href: links.facebook.href,
    },
    {
      src: SOCIAL_MEDIA_ICON_URLS.linkedin,
      tracking: 'linkedin_link',
      alt: _t('Coursera Linkedin'),
      href: links.linkedin.href,
    },
    {
      src: SOCIAL_MEDIA_ICON_URLS.twitter,
      tracking: 'twitter_link',
      alt: _t('Coursera Twitter'),
      href: links.twitter.href,
    },
    {
      src: SOCIAL_MEDIA_ICON_URLS.youtube,
      tracking: 'youtube_link',
      alt: _t('Coursera YouTube'),
      href: links.youtube.href,
    },
    {
      src: SOCIAL_MEDIA_ICON_URLS.instagram,
      tracking: 'instagram_link',
      alt: _t('Coursera Instagram'),
      href: links.instagram.href,
    },
    {
      src: SOCIAL_MEDIA_ICON_URLS.tiktok,
      tracking: 'tiktok_link',
      alt: _t('Coursera TikTok'),
      href: links.tiktok.href,
    },
  ];
};

const bem = initBem('SocialMediaLinksV2');

/**
 * @todo This component duplicates SocialMediaLinks.
 * Consider consolidating them, and use the SocialMediaLinks component instead.
 */
export default function SocialMediaLinksV2({ enableNewDefensiveSubfooter }: Props) {
  const socialMediaLinkItems = getSocialMediaLinkItems();
  const socialMediaListClasses = classNames(bem('social-media-list'), {
    [bem('social-media-list--in-new-experiment')]: enableNewDefensiveSubfooter,
  });

  return (
    <div className={bem('', '', 'lohp-rebrand')}>
      <ul className={socialMediaListClasses}>
        {socialMediaLinkItems.map((mediaItem) => (
          <li className={bem('social-media-list-item')} key={mediaItem.tracking}>
            <TrackedLink2
              trackingName={mediaItem.tracking}
              href={mediaItem.href}
              target="_blank"
              rel="noopener noreferrer"
              key={mediaItem.tracking}
            >
              <LazyImgix src={mediaItem.src} maxHeight={28} maxWidth={28} height={28} width={28} alt={mediaItem.alt} />
            </TrackedLink2>
          </li>
        ))}
      </ul>
    </div>
  );
}
