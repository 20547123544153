import * as React from 'react';

// lazyloaded to avoid bundling \`markdown-it\` dependency on main app load.
// preferred to use this over the default export
export const CopySingleResponseLazy = React.lazy(() =>
  import('bundles/ai-coach-platform/components/building-blocks/CopySingleResponse/CopySingleResponse').then(
    (module) => ({
      default: module.CopySingleResponse,
    })
  )
);
