import { createProductFeaturesQuery, createWithProductFeatures } from './components';
import { createProductFeaturesHook } from './hooks';
import type { Scope } from './types';

/**
 * You must declare a feature scope before you can use the React components to respond to product feature states. You can do this with `createFeatureScope`:
 *
 * @example
 * // static/bundles/some-app/features.ts
 * import { createFeatureScope } from 'bundles/product-features';
 *
 * export const { ProductFeaturesQuery, useProductFeatures, withProductFeatures } = createFeatureScope({
 *   MyNamespace: {
 *     MyFeature: true // value is unused,
 *   },
 * });
 */
export const createFeatureScope = <S extends {}>(scope: Scope<S>) => ({
  ProductFeaturesQuery: createProductFeaturesQuery(scope),
  withProductFeatures: createWithProductFeatures(scope),
  useProductFeatures: createProductFeaturesHook(scope),
});
