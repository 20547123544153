import {
  DescriptionPage_ProductVariant as DescriptionPageProductVariant,
  Specialization_ProductVariant as SpecializationProductVariant,
} from '__generated__/graphql-types';

import { shareUtmProductProfCertParam, shareUtmProductS12nParam } from 'bundles/accomplishments/constants';
import type { SavedProductStringTypes } from 'bundles/browse/types/SavedProductQueries';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';
import {
  CourseraIssuedBadge,
  ExternalCertificateS12n,
  GoogleCertificateS12n,
  NormalS12n,
  ProfessionalCertificateS12n,
} from 'bundles/s12n-common/constants/s12nProductVariants';
import ROUTE_CONFIG from 'bundles/unified-description-page-common/constants/routeConfig';

import _t from 'i18n!nls/s12n';

/* This util gives the human readible string associated with the product variant
 * in order for the user to be able to select the variant.
 * this is the only place where External Certificate can appear since it is meant for admins only.
 */
export const getProductVariantString = (variant: S12nProductVariant | SpecializationProductVariant): string => {
  switch (variant) {
    case NormalS12n:
    case SpecializationProductVariant.NormalS12N:
      return _t('Specialization');
    case ProfessionalCertificateS12n:
    case SpecializationProductVariant.ProfessionalCertificateS12N:
      return _t('Professional Certificate');
    case ExternalCertificateS12n:
    case SpecializationProductVariant.ExternalCertificateS12N:
      return _t('External Certificate');
    case GoogleCertificateS12n:
    case SpecializationProductVariant.GoogleCertificateS12N:
      return _t('Google Certificate');
    case CourseraIssuedBadge:
      return _t('Learning Path Badge');
    default: {
      const _exhaustiveCheck: never = variant;
      return _exhaustiveCheck;
    }
  }
};

/**
 * In Some instances, the S12n Variant Label should be 'Professional Certificate' for ExternalCertificateS12n.
 * In these instances, getS12nVariantProCertLabel is used.
 * In Some instances, the S12n Variant Label should be 'Specialization' for ExternalCertificateS12n.
 * In these instances, getS12nVariantLabel is used.
 * @param productVariant
 * @returns
 */
export const getS12nVariantProCertLabel = (productVariant: S12nProductVariant | string | undefined): string => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case ExternalCertificateS12n:
    case GoogleCertificateS12n:
      return _t('Professional Certificate');
    case NormalS12n:
    default:
      return _t('Specialization');
  }
};

/**
 * In Some instances, the S12n Variant Label should be 'Professional Certificate' for ExternalCertificateS12n.
 * In these instances, getS12nVariantProCertLabel is used.
 * In Some instances, the S12n Variant Label should be 'Specialization' for ExternalCertificateS12n.
 * In these instances, getS12nVariantLabel is used.
 * @param productVariant
 * @returns
 */
export const getS12nVariantLabel = (
  productVariant: S12nProductVariant | SpecializationProductVariant | string | undefined
): string => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
    case SpecializationProductVariant.ProfessionalCertificateS12N:
    case SpecializationProductVariant.GoogleCertificateS12N:
      return _t('Professional Certificate');
    case ExternalCertificateS12n:
    case NormalS12n:
    case SpecializationProductVariant.NormalS12N:
    case SpecializationProductVariant.ExternalCertificateS12N:
    default:
      return _t('Specialization');
  }
};

export const isS12n = (productVariant?: S12nProductVariant | string) => {
  return (
    productVariant === NormalS12n ||
    productVariant === ProfessionalCertificateS12n ||
    productVariant === GoogleCertificateS12n
  );
};

export const getS12nDisplayNameFromProductVariant = (
  s12nName: string,
  productVariant: SpecializationProductVariant
): string => {
  switch (productVariant) {
    case SpecializationProductVariant.ExternalCertificateS12N:
      return s12nName;
    case SpecializationProductVariant.ProfessionalCertificateS12N:
    case SpecializationProductVariant.GoogleCertificateS12N:
      return _t('#{name} Professional Certificate', { name: s12nName });
    case SpecializationProductVariant.NormalS12N:
    default:
      return _t('#{name} Specialization', { name: s12nName });
  }
};

/** @deprecated For a s12n coming from the graphql gateway, use getS12nDisplayNameFromProductVariant instead */
export const getS12nDisplayName = (s12nName: string, productVariant: S12nProductVariant): string => {
  switch (productVariant) {
    case ExternalCertificateS12n:
      return s12nName;
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
      return _t('#{name} Professional Certificate', { name: s12nName });
    case NormalS12n:
    default:
      return _t('#{name} Specialization', { name: s12nName });
  }
};

export const getS12nPaymentsDisplayName = (
  s12nName: string,
  productVariant: S12nProductVariant | DescriptionPageProductVariant | SpecializationProductVariant
): string => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case DescriptionPageProductVariant.ProfessionalCertificate:
    case GoogleCertificateS12n:
    case SpecializationProductVariant.ProfessionalCertificateS12N:
    case SpecializationProductVariant.GoogleCertificateS12N:
      return _t('#{name} Professional Certificate', { name: s12nName });
    case NormalS12n:
    case DescriptionPageProductVariant.StandardSpecialization:
    case ExternalCertificateS12n:
    case SpecializationProductVariant.NormalS12N:
    case SpecializationProductVariant.ExternalCertificateS12N:
    default:
      return _t('#{name}', { name: s12nName });
  }
};

/**
 * Get the title and paragraphs for an SDP's HowItWorks section.
 */
export const getS12nHowItWorksData = (productVariant: S12nProductVariant) => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
      return {
        title: _t('What is a Professional Certificate?'),
        paragraphs: [
          {
            title: _t('Build the Skills to Get Job Ready'),
            content: _t(
              'Whether you’re looking to start a new career, or change your current one, Professional Certificates on Coursera help you become job ready. Learn at your own pace, whenever and wherever it’s most convenient for you. Enroll today and explore a new career path with a 7 day free trial. You can pause your learning or end your subscription at any time.'
            ),
          },
          {
            title: _t('Hands-On Projects'),
            content: _t(
              "Apply your skills with hands-on projects and build a portfolio that showcases your job readiness to potential employers. You'll need to successfully finish the project(s) to earn your Certificate."
            ),
          },
          {
            title: _t('Earn a Career Credential'),
            content: _t(
              "When you complete all of the courses in the program, you'll earn a Certificate to share with your professional network as well as unlock access to career support resources to help you kickstart your new career. Many Professional Certificates have hiring partners that recognize the Professional Certificate credential and others can help prepare you for a certification exam. You can find more information on individual Professional Certificate pages where it applies."
            ),
          },
        ],
      };
    case ExternalCertificateS12n:
    case NormalS12n:
    default:
      return {
        title: _t('How the Specialization Works'),
        paragraphs: [
          {
            title: _t('Take Courses'),
            content: _t(
              // eslint-disable-next-line no-restricted-syntax
              "A Coursera Specialization is a series of courses that helps you master a skill. To begin, enroll in the Specialization directly, or review its courses and choose the one you'd like to start with. When you subscribe to a course that is part of a Specialization, you’re automatically subscribed to the full Specialization. It’s okay to complete just one course — you can pause your learning or end your subscription at any time. Visit your learner dashboard to track your course enrollments and your progress."
            ),
          },
          {
            title: _t('Hands-on Project'),
            content: _t(
              // eslint-disable-next-line no-restricted-syntax
              "Every Specialization includes a hands-on project. You'll need to successfully finish the project(s) to complete the Specialization and earn your certificate. If the Specialization includes a separate course for the hands-on project, you'll need to finish each of the other courses before you can start it."
            ),
          },
          {
            title: _t('Earn a Certificate'),
            content: _t(
              // eslint-disable-next-line no-restricted-syntax
              "When you finish every course and complete the hands-on project, you'll earn a Certificate that you can share with prospective employers and your professional network."
            ),
          },
        ],
      };
  }
};

export const getS12nCertificateUgradeText = (productVariant: S12nProductVariant) => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
      return _t('Upgrade to earn a Professional Certificate');
    case ExternalCertificateS12n:
    case NormalS12n:
    default:
      return _t('Upgrade to earn a Specialization certificate');
  }
};

export const getS12nAccomplishmentsUrl = (
  productVariant: S12nProductVariant | SpecializationProductVariant,
  certificateCode: string
) => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
    case SpecializationProductVariant.GoogleCertificateS12N:
    case SpecializationProductVariant.ProfessionalCertificateS12N:
      return `/account/accomplishments/professional-cert/${certificateCode}`;
    case ExternalCertificateS12n:
    case NormalS12n:
    default:
      return `/account/accomplishments/specialization/${certificateCode}`;
  }
};

export const getS12nAccomplishmentsCertificateUrl = (
  productVariant: S12nProductVariant | SpecializationProductVariant,
  certificateCode: string
) => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
    case SpecializationProductVariant.GoogleCertificateS12N:
    case SpecializationProductVariant.ProfessionalCertificateS12N:
      return `/account/accomplishments/professional-cert/certificate/${certificateCode}`;
    case ExternalCertificateS12n:
    case NormalS12n:
    default:
      return `/account/accomplishments/specialization/certificate/${certificateCode}`;
  }
};

/**
 * Get the link to an SDP
 */
export const getSdpUrl = (productVariant: S12nProductVariant | string, slug: string) => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
      return `/${ROUTE_CONFIG.PROFESSIONAL_CERTIFICATES.pathname}/${slug}`;
    case GoogleCertificateS12n:
      return `/${ROUTE_CONFIG.GOOGLE_CERTIFICATES.pathname}/${slug}`;
    case ExternalCertificateS12n:
    case NormalS12n:
    default:
      return `/${ROUTE_CONFIG.SPECIALIZATIONS.pathname}/${slug}`;
  }
};

export const getS12nCertificateName = (productVariant: S12nProductVariant) => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
      return _t('Certificate');
    case ExternalCertificateS12n:
    case NormalS12n:
    default:
      return _t('Specialization Certificate');
  }
};

/**
 * Map the s12n product variant to the official type recognized by the Save API
 */
export const getSaveTypeForS12nProductVariant = (
  productVariant: S12nProductVariant | string | undefined
): SavedProductStringTypes => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case ExternalCertificateS12n:
    case GoogleCertificateS12n:
      return 'PRODUCT_TYPE_PROFESSIONAL_CERTIFICATE';
    case NormalS12n:
    default:
      return 'PRODUCT_TYPE_SPECIALIZATION';
  }
};

export const getS12nCertProductUtm = (productVariant: string | undefined) => {
  if (!productVariant) {
    return '';
  }

  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
      return shareUtmProductProfCertParam;
    case ExternalCertificateS12n:
    case NormalS12n:
    default:
      return shareUtmProductS12nParam;
  }
};

export const getS12nUnenrollModalData = (productVariant: S12nProductVariant | string) => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
      return {
        canUnenrollText: _t('Un-enroll from Professional Certificate'),
        cannotUnenrollText: _t(
          "It isn't possible to unenroll from this Professional Certificate. We apologize for any inconvenience."
        ),
      };
    case ExternalCertificateS12n:
    case NormalS12n:
    default:
      return {
        canUnenrollText: _t('Un-enroll from Specialization'),
        cannotUnenrollText: _t(
          "It isn't possible to unenroll from this Specialization. We apologize for any inconvenience."
        ),
      };
  }
};

export const getCollectionItemS12nTypeName = (productVariant: string) => {
  switch (productVariant) {
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
      return 'CERTIFICATE';
    case ExternalCertificateS12n:
    case NormalS12n:
    default:
      return 'SPECIALIZATION';
  }
};

export const getShareCertificationDescription = (s12nProductVariant: S12nProductVariant): string => {
  let certificate;
  switch (s12nProductVariant) {
    case ProfessionalCertificateS12n:
    case ExternalCertificateS12n:
    case GoogleCertificateS12n:
      certificate = _t('Certificate');
      break;

    case NormalS12n:
    default:
      // eslint-disable-next-line no-restricted-syntax
      certificate = _t('Course Certificates');
      break;
  }

  return _t(
    'You can share your #{certificate} in the Certifications section of your LinkedIn profile, on printed resumes, CVs, or other documents.',
    { certificate }
  );
};

export const getEnrolledDiscountedBulkPayBodyMessage = (s12nProductVariant: S12nProductVariant) => {
  switch (s12nProductVariant) {
    case ExternalCertificateS12n:
      return _t('Pre-pay for the remaining courses in the Certificate, and get a special one-time discount.');
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
    case NormalS12n: // It's not possible to prepay for a Specialization Certificate so this may be a mistake but leaving it since it's existing behavior. https://github.com/webedx-spark/web/pull/17781#discussion_r839843861
    default:
      return _t(
        `Pre-pay for the remaining courses in the Specialization and Specialization Certificate, and get a special one-time discount.`
      );
  }
};

export const getEnrolledNotDiscountedBulkPayBodyMessage = (s12nProductVariant: S12nProductVariant) => {
  switch (s12nProductVariant) {
    case ExternalCertificateS12n:
      return _t('Pre-pay for the remaining courses in the Certificate.');
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
    case NormalS12n: // It's not possible to prepay for a Specialization Certificate so this may be a mistake but leaving it since it's existing behavior. https://github.com/webedx-spark/web/pull/17781#discussion_r839843861
    default:
      return _t('Pre-pay for the remaining courses in the Specialization and Specialization Certificate.');
  }
};

export const getNotEnrolledDiscountedBulkPayBodyMessage = (
  s12nProductVariant: S12nProductVariant,
  unownedCourseCount: number
) => {
  switch (s12nProductVariant) {
    case ExternalCertificateS12n:
      return _t(
        'Pre-pay for all #{unownedCourseCount} courses in the Certificate and get a special one-time discount.',
        { unownedCourseCount }
      );
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
    case NormalS12n:
    default:
      return _t(
        'Pre-pay for all #{unownedCourseCount} courses in the Specialization and Specialization Certificate, and get a special one-time discount.',
        { unownedCourseCount }
      );
  }
};

export const getNotEnrolledNotDiscountedBulkPayBodyMessage = (
  s12nProductVariant: S12nProductVariant,
  unownedCourseCount: number
) => {
  switch (s12nProductVariant) {
    case ExternalCertificateS12n:
      return _t('Pre-pay for all #{unownedCourseCount} courses in the Certificate.', { unownedCourseCount });
    case ProfessionalCertificateS12n:
    case GoogleCertificateS12n:
    case NormalS12n:
    default:
      return _t(`Pre-pay for all #{unownedCourseCount} courses in the Specialization and Specialization Certificate.`, {
        unownedCourseCount,
      });
  }
};
