import { useQuery } from '@apollo/client';
import type { ApolloError } from '@apollo/client';

import type {
  SearchQuery,
  SearchQueryVariables,
  SearchRequests,
  SearchResultRaw,
  SearchResults,
  SearchStates,
} from 'bundles/search-common/providers/searchTypes';
import SEARCH_QUERY from 'bundles/search-common/queries/searchQuery.graphql';
import { combineResultsWithStates, mapStateToQuery } from 'bundles/search-common/utils/providerUtils';

export type Return = {
  isLoading: boolean;
  error?: ApolloError;
  results?: SearchResults;
};

type QueryOptions = {
  skip?: boolean;
};

/**
 * useSearchQuery is a hook that handles search queries.
 * It takes in searchStates and queryOptions as parameters and returns an object with loading, error, and results.
 * The hook maps the searchStates to query parameters and executes the search query.
 *
 * @param {SearchStates} searchStates - The search states to be used, default is an empty object.
 * @param {QueryOptions} queryOptions - The query options to be used, default is an empty object.
 * @returns {Return} An object containing isLoading, error, and results.
 *
 * @example
 * useSearchQuery(searchStates, queryOptions);
 */
function useSearchQuery(searchStates: SearchStates = {}, queryOptions: QueryOptions = {}): Return {
  const searchStatesArray = Object.values(searchStates);
  const mappedQueryParams: SearchRequests = searchStatesArray?.map(mapStateToQuery);
  const { loading, error, data } = useQuery<SearchQuery, SearchQueryVariables>(SEARCH_QUERY, {
    ...queryOptions,
    variables: { requests: mappedQueryParams },
    context: { clientName: 'gatewayGql' },
    errorPolicy: 'all',
  });
  const results = combineResultsWithStates(searchStatesArray, data?.SearchResult?.search as SearchResultRaw[]);

  return { isLoading: loading, error, results };
}

export default useSearchQuery;
