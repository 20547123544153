import { track } from '@coursera/event-pulse/sdk';

import { OTHER_SECTION_TYPE } from 'bundles/megamenu/constants/constants';
import type { MegaMenuContent } from 'bundles/megamenu/types/MegaMenuContent';
import { MEGAMENU_CONTENT_TYPE } from 'bundles/megamenu/types/MegaMenuContent';

import _t from 'i18n!nls/megamenu';

type Links = {
  title: string;
  id: string;
  url?: string;
  links: { label: string; id: string; url: string }[];
}[];

const links = () =>
  [
    {
      title: _t('Explore roles'),
      id: 'career',
      url: '/career-academy',
      links: [
        {
          label: _t('Data Analyst'),
          id: 'data-analyst',
          url: '/career-academy/roles/data-analyst',
        },
        {
          label: _t('Project Manager'),
          id: 'project-manager',
          url: '/career-academy/roles/project-manager',
        },
        {
          label: _t('Cyber Security Analyst'),
          id: 'cyber-security-analyst',
          url: '/career-academy/roles/cyber-security-analyst',
        },
        {
          label: _t('Data Scientist'),
          id: 'data-scientist',
          url: '/career-academy/roles/data-scientist',
        },
        {
          label: _t('Business Intelligence Analyst'),
          id: 'business-intelligence-analyst',
          url: '/career-academy/roles/business-intelligence-analyst',
        },
        {
          label: _t('Digital Marketing Specialist'),
          id: 'digital-marketing-specialist',
          url: '/career-academy/roles/digital-marketing-specialist',
        },
        {
          label: _t('UI / UX Designer'),
          id: 'user-interface-user-experience-ui-ux-designer',
          url: '/career-academy/roles/user-interface-user-experience-ui-ux-designer',
        },
        {
          label: _t('Machine Learning Engineer'),
          id: 'machine-learning-engineer',
          url: '/career-academy/roles/machine-learning-engineer',
        },
        {
          label: _t('Social Media Specialist'),
          id: 'social-media-strategist-specialist',
          url: '/career-academy/roles/social-media-strategist-specialist',
        },
        {
          label: _t('Computer Support Specialist'),
          id: 'computer-support-specialist',
          url: '/career-academy/roles/computer-support-specialist',
        },
      ],
    },
    {
      title: _t('Explore categories'),
      id: 'categories',
      url: '/browse',
      links: [
        {
          label: _t('Business'),
          id: 'business',
          url: '/browse/business',
        },
        {
          label: _t('Data Science'),
          id: 'data-science',
          url: '/browse/data-science',
        },
        {
          label: _t('Information Technology'),
          id: 'information-technology',
          url: '/browse/information-technology',
        },
        {
          label: _t('Computer Science'),
          id: 'computer-science',
          url: '/browse/computer-science',
        },
        {
          label: _t('Life Sciences'),
          id: 'life-sciences',
          url: '/browse/life-sciences',
        },
        {
          label: _t('Physical Science and Engineering'),
          id: 'physical-science-and-engineering',
          url: '/browse/physical-science-and-engineering',
        },
        {
          label: _t('Personal Development'),
          id: 'personal-development',
          url: '/browse/personal-development',
        },
        {
          label: _t('Social Sciences'),
          id: 'social-sciences',
          url: '/browse/social-sciences',
        },
        {
          label: _t('Language Learning'),
          id: 'language-learning',
          url: '/browse/language-learning',
        },
        {
          label: _t('Arts and Humanities'),
          id: 'arts-and-humanities',
          url: '/browse/arts-and-humanities',
        },
      ],
    },
    {
      title: _t('Explore trending skills'),
      id: 'skills',
      url: undefined,
      links: [
        {
          label: _t('Python'),
          id: 'python',
          url: '/courses?query=python',
        },
        {
          label: _t('Artificial Intelligence'),
          id: 'artificial-intelligence',
          url: '/courses?query=artificial%20intelligence',
        },
        {
          label: _t('Excel'),
          id: 'excel',
          url: '/courses?query=microsoft%20excel',
        },
        {
          label: _t('Machine Learning'),
          id: 'machine-learning',
          url: '/courses?query=machine%20learning&skills=Machine%20Learning',
        },
        {
          label: _t('SQL'),
          id: 'sql',
          url: '/courses?query=sql',
        },
        {
          label: _t('Project Management'),
          id: 'project-management',
          url: '/courses?query=project%20management',
        },
        {
          label: _t('Power BI'),
          id: 'power-bi',
          url: '/courses?query=power%20bi',
        },
        {
          label: _t('Marketing'),
          id: 'marketing',
          url: '/courses?query=marketing&skills=Marketing',
        },
        {
          label: _t('Data Science'),
          id: 'data-science',
          url: '/courses?query=data%20science&topic=Data%20Science',
        },
        {
          label: _t('Cybersecurity'),
          id: 'cybersecurity',
          url: '/courses?query=cybersecurity',
        },
      ],
    },
    {
      title: _t('Earn a Professional Certificate'),
      id: 'professional-certificates',
      url: '/search?productTypeDescription=Professional%20Certificates&sortBy=BEST_MATCH',
      links: [
        {
          label: _t('Business'),
          id: 'business',
          url: '/search?productTypeDescription=Professional%20Certificates&topic=Business&sortBy=BEST_MATCH',
        },
        {
          label: _t('Computer Science'),
          id: 'computer-science',
          url: '/search?productTypeDescription=Professional%20Certificates&topic=Computer%20Science&sortBy=BEST_MATCH',
        },
        {
          label: _t('Data Science'),
          id: 'data-science',
          url: '/search?productTypeDescription=Professional%20Certificates&topic=Data%20Science&sortBy=BEST_MATCH ',
        },
        {
          label: _t('Information Technology'),
          id: 'information-technology',
          url: '/search?productTypeDescription=Professional%20Certificates&topic=Information%20Technology&sortBy=BEST_MATCH',
        },
      ],
    },
    {
      title: _t('Earn an online degree'),
      id: 'online-degrees',
      url: '/degrees',
      links: [
        {
          label: _t("Bachelor's Degrees"),
          id: 'bachelors-degree',
          url: '/degrees/bachelors',
        },
        {
          label: _t("Master's Degrees"),
          id: 'masters-degrees',
          url: '/degrees/masters',
        },
        {
          label: _t('Postgraduate Programs'),
          id: 'postgraduate-programs',
          url: '/degrees/postgraduate-studies',
        },
      ],
    },
  ] as const satisfies Links;

export type SimplifiedMegamenuSectionIds = ReturnType<typeof links>[number]['id'];

export const simplifiedMegaMenuContent = (): MegaMenuContent[] =>
  links().map((section) => ({
    type: MEGAMENU_CONTENT_TYPE.section,
    sectionType: OTHER_SECTION_TYPE,
    href: section.url ?? '',
    label: section.title,
    name: section.id,
    onButtonClick: () => {
      track('click_megamenu_item', {
        megamenuItemLink: section.url ?? '',
        megamenuItemName: section.id,
        megamenuItemType: 'other',
        megamenuSection: OTHER_SECTION_TYPE,
      });
    },
    children: section.links.map((link) => {
      return {
        type: MEGAMENU_CONTENT_TYPE.link,
        href: link.url,
        label: link.label,
        name: link.id,
        onButtonClick: () => {
          track('click_megamenu_item', {
            megamenuItemLink: link.url,
            megamenuItemName: link.id,
            megamenuItemType: 'other',
            megamenuSection: OTHER_SECTION_TYPE,
          });
        },
      };
    }),
    ...(section.url
      ? {
          footer: {
            type: MEGAMENU_CONTENT_TYPE.link,
            href: section.url,
            label: _t('View all'),
            name: section.id,
            onButtonClick: () => {
              track('click_megamenu_item', {
                megamenuItemLink: section.url,
                megamenuItemName: section.id,
                megamenuItemType: 'view_all_link',
                megamenuSection: OTHER_SECTION_TYPE,
              });
            },
          },
        }
      : {}),
  }));
