/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';

import createLoadableComponent from 'js/lib/createLoadableComponent';
import user from 'js/lib/user';
import type UserAgentInfo from 'js/lib/useragent';

import { CloseIcon, MenuIcon } from '@coursera/cds-icons';
import { track } from '@coursera/event-pulse/sdk';

import GoogleID from 'bundles/authentication/shared/components/GoogleID';
import type { Domain } from 'bundles/browse/components/types/MegaMenu';
import { impressShowNewMegamenu, shouldShowNewMegamenu } from 'bundles/megamenu/utils/experimentUtils';
import type SwitcherSelectionsType from 'bundles/naptimejs/resources/programSwitcherSelections.v1';
import HeaderMobileLogo from 'bundles/page-header/components/mobile/HeaderMobileLogo';
import MobileAuthorizationButtons from 'bundles/page-header/components/mobile/MobileAuthorizationButtons';
import type { EnterpriseProgram, ThirdPartyOrganization } from 'bundles/page-header/components/mobile/MobileHeader';
import PageHeaderContext from 'bundles/page-header/contexts/PageHeaderContext';
import TrackedButton from 'bundles/page/components/TrackedButton';
import LazyLoadingHandler from 'bundles/page/components/shared/LazyLoadingHandler';

import _t from 'i18n!nls/page';

import 'css!bundles/page-header/components/mobile/__styles__/MobileHeaderNav';

const LoadableMobileLoggedInNav = createLoadableComponent(
  () => import('bundles/page-header/components/mobile/MobileLoggedInNav'),
  LazyLoadingHandler
);

const LoadableMobileLoggedOutNav = createLoadableComponent(
  () => import('bundles/page-header/components/mobile/MobileLoggedOutNav'),
  LazyLoadingHandler
);

const LoadableMobileAuthorizationButtons = createLoadableComponent(
  () => import('bundles/page-header/components/mobile/MobileAuthorizationButtons'),
  LazyLoadingHandler
);

type Props = {
  globalNavTest4Variant?: string;
  toggleMobileCourseMenu?: () => void;
  shouldSkipOptionalExternalDataFetch?: boolean;
  switcherSelections?: SwitcherSelectionsType;
  userAgent?: UserAgentInfo;
  domains?: Array<Domain>;
  isEnterprise?: boolean;
  currentProgram: EnterpriseProgram;
  thirdPartyOrganization?: ThirdPartyOrganization;
  hideMobileNavLinks?: boolean;
};

type State = {
  menuIsOpen: boolean;
  isMenuButtonToggled: boolean;
};

const styles = {
  nav: css`
    line-height: 1;
  `,
  navIcon: css`
    && {
      bottom: unset;
      position: static;
      padding: var(--cds-spacing-100);
    }
  `,

  navIconLarge: css`
    && {
      bottom: 50%;
      transform: translateY(50%);
    }
  `,
};

class MobileHeaderNav extends React.Component<Props, State> {
  declare bodyNode: HTMLBodyElement | null | undefined;

  static contextTypes = {
    router: PropTypes.object,
  };

  declare context: LegacyContextType<typeof MobileHeaderNav.contextTypes>;

  state = {
    menuIsOpen: false,
    isMenuButtonToggled: false,
  };

  componentDidMount() {
    this.bodyNode = document.querySelector('body');
  }

  openHeaderMenu = () => {
    const { bodyNode } = this;

    this.setState({ menuIsOpen: true }, () => {
      if (bodyNode) bodyNode.classList.add('is-mobileMenuOpen');
    });
  };

  closeHeaderMenu = () => {
    const { bodyNode } = this;

    this.setState({ menuIsOpen: false }, () => {
      if (bodyNode) {
        bodyNode.classList.remove('is-mobileMenuOpen');
        bodyNode.classList.remove('is-mobileSubMenuOpen');
      }
    });
  };

  handleToggleMenubutton = () => {
    const { toggleMobileCourseMenu } = this.props;

    this.setState((prevState) => ({ isMenuButtonToggled: !prevState.isMenuButtonToggled }));

    impressShowNewMegamenu();
    if (shouldShowNewMegamenu()) {
      track('open_navigation_menu', {
        navigationType: 'megamenu',
      });
    }
    // toggle course menu instead of nav when in a course
    if (toggleMobileCourseMenu) {
      toggleMobileCourseMenu();
    } else {
      this.openHeaderMenu();
    }
  };

  renderNav = () => {
    const isLoggedIn = user.isAuthenticatedUser();
    const { menuIsOpen } = this.state;
    const {
      shouldSkipOptionalExternalDataFetch,
      switcherSelections,
      isEnterprise,
      thirdPartyOrganization,
      hideMobileNavLinks,
    } = this.props;

    if (isLoggedIn) {
      return (
        <LoadableMobileLoggedInNav
          data-unit="logged-in-nav"
          showNav={menuIsOpen}
          // @ts-expect-error TODO: not being used? sorry I have no context here
          shouldSkipOptionalExternalDataFetch={shouldSkipOptionalExternalDataFetch}
          switcherSelections={switcherSelections}
          hasParentContainer
          isEnterprise={isEnterprise}
          thirdPartyOrganization={thirdPartyOrganization}
          hideMobileNavLinks={hideMobileNavLinks}
          closeMenu={this.closeHeaderMenu}
        />
      );
    } else {
      return (
        <LoadableMobileLoggedOutNav
          data-unit="logged-out-nav"
          // @ts-expect-error TODO: not being used? sorry I have no context here
          showNav={menuIsOpen}
          onAuth={this.closeHeaderMenu}
          hasParentContainer
          hideMobileNavLinks={hideMobileNavLinks}
        />
      );
    }
  };

  renderNavAuth = () => {
    const { menuIsOpen } = this.state;
    const { hideMobileNavLinks } = this.props;
    const isLoggedIn = user.isAuthenticatedUser();
    if (!isLoggedIn) {
      return (
        <LoadableMobileAuthorizationButtons
          showNav={menuIsOpen}
          onAuth={this.closeHeaderMenu}
          hideMobileNavLinks={hideMobileNavLinks}
        />
      );
    } else return null;
  };

  render() {
    const isLoggedIn = user.isAuthenticatedUser();
    const { menuIsOpen, isMenuButtonToggled } = this.state;
    const { toggleMobileCourseMenu, thirdPartyOrganization, currentProgram } = this.props;

    return (
      <PageHeaderContext.Consumer>
        {({ isSimplifiedPageHeader }) => (
          <nav {...(isSimplifiedPageHeader ? { css: styles.nav } : {})} aria-label={_t('Main Menu')}>
            {!isLoggedIn && <GoogleID />}
            <TrackedButton
              onClick={this.handleToggleMenubutton}
              className="c-mobile-toggle-button"
              trackingName="mobile_header_nav_button"
              data-e2e="mobile-nav-icon"
              data={{ name: 'open-nav' }}
              aria-label={_t('Open Navigation Menu')}
              aria-expanded={isMenuButtonToggled}
              {...(isSimplifiedPageHeader ? { css: styles.navIcon } : { css: styles.navIconLarge })}
            >
              {isSimplifiedPageHeader ? (
                <MenuIcon size="medium" />
              ) : (
                <MenuIcon size="large" display="block" height="26px" width="26px" />
              )}
            </TrackedButton>
            <span className="nostyle">
              <ul className={classNames('mobile-header-menu', { 'show-nav': menuIsOpen })}>
                {!toggleMobileCourseMenu && menuIsOpen && this.renderNav()}
              </ul>
              {menuIsOpen && this.renderNavAuth()}
              {menuIsOpen && (
                <div className="c-mobile-header-wrapper">
                  <div className="c-mobile-header-controls horizontal-box" style={{ zIndex: 1000 }}>
                    <HeaderMobileLogo thirdPartyOrganization={thirdPartyOrganization} currentProgram={currentProgram} />
                  </div>
                  <TrackedButton
                    className="c-close-mobile-nav"
                    onClick={this.closeHeaderMenu}
                    data={{ name: 'close' }}
                    aria-label={_t('Close Navigation Menu')}
                    style={{ zIndex: 1000 }}
                  >
                    {isSimplifiedPageHeader ? (
                      <CloseIcon size="medium" />
                    ) : (
                      <CloseIcon style={{ height: '26px', width: '26px' }} />
                    )}
                  </TrackedButton>
                </div>
              )}
            </span>
          </nav>
        )}
      </PageHeaderContext.Consumer>
    );
  }
}

export default MobileHeaderNav;
