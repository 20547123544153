/* eslint-disable new-cap,@babel/new-cap,camelcase */
// ^ The convention of FullStory is FS.

/**
 * IMPORTANT.
 *
 * FullStory script is injected through GTM.
 * The script was intentionally modified to callback window._fs_on_load when it's loaded.
 * If you need to update the library, please update the GTM tag.
 *
 */

/**
 * MAKING CHANGES TO THIS FILE? Please, make sure you update the server file accordingly.
 *
 * Both files should export the same functions and variables.
 */
import type { FullStory } from '@fullstory/browser';
import { memoize } from 'lodash';

import * as user from 'js/lib/user';

declare global {
  interface Window {
    /**
     * https://developer.fullstory.com/browser/fullcapture/capture-data/#manually-delay-data-capture
     *
     * To manually delay capture, set window['_fs_capture_on_startup'] to false. When you are ready
     * to start capture, you can call FS('start').
     */
    _fs_capture_on_startup?: boolean;

    /**
     * FullStory global variable.
     */
    FS: typeof FullStory | undefined;

    /**
     * It is a callback that is called when FullStory script is loaded.
     */
    _fs_on_load?: () => void;
  }
}

type StartOptions = {
  disableForCourserians?: boolean;
};

// Don't have much control over the FullStory global variable naming.

window._fs_capture_on_startup = false;
// ^ We don't want to start recording sessions for everybody, we want to avoid Courserians or other kind of
// superusers.

/**
 * It returns a promise that resolves when the FullStory script is loaded.
 * The function is memoized now so we don't inject the script multiple times.
 * In the future, we won't inject the script in here, but through GTM.
 */
const load = memoize(async (): Promise<typeof FullStory> => {
  // Set the promise to resolve when the FullStory script is loaded.
  const loader = new Promise<void>((ok) => {
    window._fs_on_load = ok;
  });

  // The FullStory script is injected through GTM.
  // The script was intentionally modified to callback window._fs_on_load when it's loaded.
  // If you need to update the library, please update the GTM tag.

  // Check if the script has not been loaded yet.
  if (!window.FS) {
    await loader;
  }

  // Otherwise, return FS immediately;
  return window.FS!;
});

/**
 * @deprecated
 * There is a single use of this function in the codebase.
 *
 * It sends an event to FullStory.
 */
async function event(eventName: string, data: object) {
  const FS = await load();

  FS('trackEvent', {
    name: eventName,
    properties: data,
  });
}

/**
 * It sets the FullStory properties for the user.
 * @param value - The properties to set for the user.
 */
async function set(value: object) {
  const FS = await load();

  FS('setProperties', { properties: value, type: 'user' });
}

/**
 * It starts the FullStory session.
 * @param disableForCourserians - If true, it will disable FullStory for Courserians. Default is true.
 */
async function start({ disableForCourserians = true }: StartOptions = {}) {
  // Short circuit if the user is a Courserian.
  if (disableForCourserians && user.isCourserian()) return;

  const FS = await load();

  FS('start');

  FS('setIdentity', {
    uid: user.get().external_id,
  });
}

/**
 * It stops the FullStory session.
 */
async function stop() {
  const FS = await load();

  FS('shutdown');
}

export { event, set, start, stop };
