import { isAuthenticatedUser } from 'js/lib/user';

import GrowthAcquisition from 'bundles/epic/clients/GrowthAcquisition';

export function getShouldShowUnregisteredOnboarding() {
  if (isAuthenticatedUser()) return false;

  const valueFromEpic = GrowthAcquisition.get('useSubscriptionsForwardDiscoverySessionBased');
  return !(valueFromEpic === 'control' || valueFromEpic === 'variant2');
}

export function getShouldShowCourseraPlusMerchandisingPlacements() {
  if (isAuthenticatedUser()) {
    return GrowthAcquisition.get('useSubscriptionsForwardDiscoveryUserBased') !== 'control';
  }

  const valueFromEpic = GrowthAcquisition.get('useSubscriptionsForwardDiscoverySessionBased');
  return !(valueFromEpic === 'control' || valueFromEpic === 'variant1');
}

/**
 * @description For "start free trial" CTAs
 */
export function getShouldShowUpdatedEnrollmentCTAs() {
  if (isAuthenticatedUser()) {
    return GrowthAcquisition.get('useSubscriptionsForwardDiscoveryUserBased') !== 'control';
  }

  const valueFromEpic = GrowthAcquisition.get('useSubscriptionsForwardDiscoverySessionBased');
  return valueFromEpic === 'variant2' || valueFromEpic === 'variant3';
}

export function getShouldShowCourseraPlusEntrypointInNav() {
  if (isAuthenticatedUser()) return false;

  const valueFromEpic = GrowthAcquisition.get('useSubscriptionsForwardDiscoverySessionBased');
  return valueFromEpic === 'variant2' || valueFromEpic === 'variant3';
}

/**
 * @description For XDP changes, C+ entrypoint in nav, C+ landing page redesign (if applicable), C+ annual 7 day free trial
 */
export function getShouldShowXDPAndCourseraPlusLandingPageChanges() {
  if (!isAuthenticatedUser()) return false;

  return GrowthAcquisition.get('useSubscriptionsForwardDiscoveryUserBased') !== 'control';
}

export function getShouldShowEnrollmentFlowChanges() {
  if (!isAuthenticatedUser()) return false;

  const valueFromEpic = GrowthAcquisition.get('useSubscriptionsForwardDiscoveryUserBased');
  return valueFromEpic === 'variant2' || valueFromEpic === 'variant3';
}

/**
 * @description For checkout page redesign and toggle
 */
export function getShouldShowCheckoutPageChanges() {
  if (!isAuthenticatedUser()) return false;

  return GrowthAcquisition.get('useSubscriptionsForwardDiscoveryUserBased') === 'variant3';
}

/**
 * @description For post-purchase upsell redesign, post-purchase confirmation, profile treatment
 */
export function previewShouldShowPostPurchaseChanges() {
  if (!isAuthenticatedUser()) return false;

  const valueFromEpic = GrowthAcquisition.preview('useSubscriptionsForwardDiscoveryUserBased');
  return valueFromEpic === 'variant2' || valueFromEpic === 'variant3';
}
