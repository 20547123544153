import type { Feedback } from 'bundles/ai-course-coach/store/types';
import type { ROLE_PLAY_PLUGIN_NAME, SOCRATIC_DIALOGUE_PLUGIN_NAME } from 'bundles/coach-item/common/constants';
import type { WidgetSession } from 'bundles/naptimejs/resources/__generated__/OnDemandBoxViewAnnotationSessionsV1';

export enum CoachItemType {
  SocraticDialogue = 'socraticDialogue',
  RolePlay = 'rolePlay',
}

export enum AiPersonaBehavior {
  Cooperative = 'COOPERATIVE',
  Skeptical = 'SKEPTICAL',
  Demanding = 'DEMANDING',
}

export enum PromptParameter {
  // Dialogue
  purposeOfActivity = 'coachItem.purposeOfActivity', // Purpose of activity

  // Common
  authorRubricAdvanced = 'coachItem.authorRubricAdvanced', // Advanced prompt
  authorRubricIntermediate = 'coachItem.authorRubricIntermediate', // Intermediate prompt
  authorRubricBeginner = 'coachItem.authorRubricBeginner', // Beginner prompt

  // Role Play
  scenarioDefinition = 'coachItem.scenarioDefinition', // Scenario definition
  aiPersonaRole = 'coachItem.aiPersonaRole', // Role of AI persona
}

export type PromptParameters = Record<PromptParameter, string>;

export enum AdvancedPromptParameter {
  // Role Play
  aiPersonaBehavior = 'coachItem.aiPersonaBehavior', // Behavior of AI persona
  tasks = 'coachItem.tasks', // Tasks 1-5
}

export type AdvancedPromptParameters = {
  [AdvancedPromptParameter.aiPersonaBehavior]: AiPersonaBehavior;
  [AdvancedPromptParameter.tasks]: Array<string>;
};

export const InputValidationCallbackNames = {
  coverPageInstructions: 'coverPageInstructions',
  // Everything else just recycles the network-sent prompt-parameter name
  ...PromptParameter,
  ...AdvancedPromptParameter,
};

export type CoachItemConfigurationData = {
  versionedCoachItemType: string;
  coverPageInstructions: string;
  promptParameters: Partial<PromptParameters>;
  advancedPromptParameters: Partial<AdvancedPromptParameters>;
  assetIds: Array<string>;
};

export interface CoachItemWidgetSession extends WidgetSession {
  configuration: {
    data: CoachItemConfigurationData;
  };
}

export enum AssetProcessingState {
  /** Indexing API call is currently running */
  Processing = 'processing',
  /** Indexing API call completed successfully */
  RecentlyComplete = 'recentlyComplete',
  /** Asset addition has been successful and is part of the activity */
  Complete = 'complete',
  /** Indexing API call failed */
  Error = 'error',
  /** The same asset was added to this course twice */
  Duplicate = 'duplicate',
}

export enum AiEmbeddingsIndexDocumentErrorCode {
  /** No error */
  None = 'NONE',
  /** Indicates asset cannot be found */
  NotFoundError = 'NOT_FOUND_ERROR',
  /** Indicates general processing error */
  ProcessingError = 'PROCESSING_ERROR',
  /** Indicates asset file size exceeds maximum */
  SizeLimitExceededError = 'SIZE_LIMIT_EXCEEDED_ERROR',
  /** Indicates asset is of unsupported file type */
  UnsupportedFileTypeError = 'UNSUPPORTED_FILE_TYPE_ERROR',
}

/** A local definition of asset, supporting the processing workflow */
export interface AssetManagerAsset {
  id: string;
  /** Local UI state regarding the asynchronous process to index the document */
  processingState: AssetProcessingState;
  /** Error code from AiEmbeddings_IndexDocumentAsset */
  errorCode: AiEmbeddingsIndexDocumentErrorCode;
  /**
   * The unix timestamp for when the asset was persisted in React state.
   * Used for maintaining the assets' position in the list.
   * */
  reactCreationTime: number;
}

export type RemoveAssetManagerAsset = (assetKey: string, asset: AssetManagerAsset) => void;

export enum AuthoringPreviewUiMode {
  /** One or more required form fields are missing data */
  MISSING_INFO = 'MISSING_INFO',
  /** There is a validation or other error in the authoring form that must be addressed */
  ERROR = 'ERROR',
  /** The preview pane is available to use */
  ACTIVE = 'ACTIVE',
}

export enum AuthoringMode {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export type CoachItemPluginName = typeof SOCRATIC_DIALOGUE_PLUGIN_NAME | typeof ROLE_PLAY_PLUGIN_NAME;

export type Hint = {
  messageId: string;
  message: string;
  referencedMessageId: string;
  userFeedback?: Feedback | null;
};

export type Task = {
  id: string;
  title: string;
  completed: boolean;
};

export type TaskListItem = Pick<Task, 'id' | 'title'>;
export type TaskProgressItem = Pick<Task, 'id' | 'completed'> & { reasoning?: string };
